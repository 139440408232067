export const PREVIEW_MODE_SEARCH_PARAM = 'view_mode';

// ! enums & types
export enum EAppConfigurationPage {
	HOME = 'home',
	ALL_SERVICES = 'all-services',
	//
	HUB = 'hub',
	ACCOUNT = 'account',
	//
	VERTICALS = 'verticals',
	BANNERS = 'banners',
	DEEP_LINKS = 'deep-links',
}
