import { createContext, useContext, useMemo } from 'react';
import type { CSSProperties, PropsWithChildren } from 'react';
import { ISortableItemContext, ISortableItemProps } from './types';
import { Button } from 'antd';
import { HolderOutlined } from '@ant-design/icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const SortableItemContext = createContext<ISortableItemContext>({
	attributes: {},
	listeners: undefined,
	ref() {},
});

export function SortableItem({ children, id }: PropsWithChildren<ISortableItemProps>) {
	const { attributes, isDragging, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({
		id,
	});
	const context = useMemo(
		() => ({
			attributes,
			listeners,
			ref: setActivatorNodeRef,
		}),
		[attributes, listeners, setActivatorNodeRef]
	);

	const style: CSSProperties = {
		transform: CSS.Translate.toString(transform),
		transition,

		...(isDragging
			? {
					opacity: 0.2,
			  }
			: {}),
	};

	return (
		<SortableItemContext.Provider value={context}>
			<div
				className='SortableItem'
				ref={setNodeRef}
				style={style}
			>
				{children}
			</div>
		</SortableItemContext.Provider>
	);
}

export function DragHandle() {
	const { attributes, listeners, ref } = useContext(SortableItemContext);

	return (
		<Button
			type='text'
			className='DragHandle'
			{...attributes}
			{...listeners}
			ref={ref}
		>
			<HolderOutlined />
		</Button>
	);
}
