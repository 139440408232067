import { lazy } from 'react';
import { ProductProvider } from 'hooks/useProduct';
import { TRouteObject } from 'configs/routes';
import { PRODUCT_DETAILS_TAB_ROUTES } from 'pages/Vendors/VendorDetails/Tabs/Products/routes.product-tabs';

const BranchMenusTabContent = lazy(() => import('./BranchMenusTabContent'));
const BranchMenuView = lazy(() => import('./BranchMenuView'));
const ProductPageLayout = lazy(
	() => import('pages/Vendors/VendorDetails/Tabs/Products/ProductDetails/ProductPageLayout')
);

export const VENDOR_BRANCH_MENUS_ROUTES: TRouteObject[] = [
	{
		index: true,
		element: <BranchMenusTabContent />,
	},
	{
		path: ':menuId',
		children: [
			{
				index: true,
				element: <BranchMenuView />,
			},
			{
				path: 'product/:productId',
				element: (
					<ProductProvider>
						<ProductPageLayout />
					</ProductProvider>
				),
				handle: {
					showOnNav: false,
				},
				children: [...PRODUCT_DETAILS_TAB_ROUTES],
			},
		],
	},
];
