import { IGenericVendor, IVendorSwimlaneDetails, IVendorsSwimlane } from 'types/api';
import { ISelectedSwimlaneVendors, IVendorOption } from './types';

export const transformIGenericVendorToIVendorOption = (vendor: IGenericVendor): IVendorOption => ({
	id: vendor.id,
	key: `#${vendor.id} - ${vendor.name} | ${vendor.name_ar}`,
	label: `#${vendor.id} - ${vendor.name} | ${vendor.name_ar}`,
	value: vendor.id,
	image: vendor.image,
});

export const transformIVendorSwimlaneDetailsToIVendorSwimlane = (
	vendors: IVendorSwimlaneDetails[]
): ISelectedSwimlaneVendors[] => {
	return vendors.map((vendor) => ({
		id: vendor.vendor_id,
		position: vendor.position,
		label: `#${vendor.vendor_id} - ${vendor.name} | ${vendor.name_ar}`,
		key: `#${vendor.vendor_id} - ${vendor.name} | ${vendor.name_ar}`,
		value: vendor.vendor_id,
		image: vendor.image,
	}));
};

export const transformIVendorOptionToIVendorSwimlane = (selectedVendors: IVendorOption[]): IVendorsSwimlane[] => {
	return selectedVendors.map((vendor: IVendorOption, index: number) => {
		return { vendor_id: vendor.id, position: index + 1 };
	});
};
