import { useTranslation } from 'react-i18next';
import { useConfirmModal, useNewHttpClient } from 'hooks';
import { BANNERS_API } from 'configs/api';
import { TEmptyFunction } from 'types/common';
import { App } from 'antd';

interface IUpdateBannerStatusHookData {
	loading: boolean;
	onActivateBanner: (id: number) => void;
	onDeactivateBanner: (id: number) => void;
}

const useUpdateBannerStatus = (successCb?: TEmptyFunction): IUpdateBannerStatusHookData => {
	const { openConfirmModal } = useConfirmModal();
	const { t: tBanners } = useTranslation('banners');
	const { message } = App.useApp();

	// ! http clients
	const activateBannerHttpClient = useNewHttpClient();
	const deactivateBannerHttpClient = useNewHttpClient();

	const loading = activateBannerHttpClient.isLoading || deactivateBannerHttpClient.isLoading;

	// ! handlers
	const onActivateBanner = (id: number) => {
		openConfirmModal({
			title: tBanners('activate_modal.title', { id }),
			onOk: () =>
				activateBannerHttpClient.request({
					requestConfig: BANNERS_API.activateBannerConfig(id),
					successCallback: () => {
						message.success(tBanners('success_messages.update'));
						successCb && successCb();
					},
				}),
		});
	};

	const onDeactivateBanner = (id: number) => {
		openConfirmModal({
			title: tBanners('deactivate_modal.title', { id }),
			onOk: () =>
				deactivateBannerHttpClient.request({
					requestConfig: BANNERS_API.deactivateBannerConfig(id),
					successCallback: () => {
						message.success(tBanners('success_messages.update'));
						successCb && successCb();
					},
				}),
		});
	};

	// ! hook return data
	return {
		loading,
		onActivateBanner,
		onDeactivateBanner,
	};
};

export default useUpdateBannerStatus;
