import { EChoiceOption, EStatus, Nullable } from 'types/common';
import { IDefaultCategory } from './categories';
import { IMenu } from './vendors';

// ! enums
export enum ENewProductDiscountType {
	NONE = 'none',
	AMOUNT = 'amount',
	PERCENTAGE = 'percentage',
}

// ! interfaces
interface IStatus {
	status: EStatus;
}

export interface IOrdered {
	sort_order: number;
}

export interface IProductActions {
	actions: {
		can_modify_product_max_order_quantity: boolean;
		can_modify_product_variant_barcode: boolean;
		can_modify_product_variant_sku: boolean;
	};
}

export interface IProduct extends IStatus, IProductActions {
	id: number;
	name: string;
	name_ar: string;
	description?: string;
	description_ar?: string;
	image: string;
	price: number;
	discount_value?: number;
	discount_type?: ENewProductDiscountType;
	maximum_order_quantity: number;

	variants_group_name: string;
	variants_group_name_ar: string;
	variants: IVariant[];
}

export interface IProductListResponse extends IProductActions {
	id: IProduct['id'];
	menus: Array<{
		id: IMenu['id'];
		name: IMenu['name'];
	}>;

	name: IProduct['name'];
	name_ar: IProduct['name_ar'];
	image: IProduct['image'];
	price: IProduct['price'];
	status: IProduct['status'];
	maximum_order_quantity: IProduct['maximum_order_quantity'];
}

export interface IVariant extends IOrdered, IStatus {
	id?: number;
	name: string;
	name_ar: string;
	price: number;
	default: EChoiceOption;
	sku: Nullable<string>;
	barcode: Nullable<string>;

	option_groups: IOptionGroup[];
}

export interface IOptionGroup extends IOrdered, IStatus {
	id?: number;
	name: string;
	name_ar: string;
	instructions: string;
	instructions_ar: string;
	limit_min: number;
	limit_max: number;
	show_selection_limitation_label: EChoiceOption;

	options: IOption[];
}
export interface IOption extends IOrdered, IStatus {
	id?: number;
	name: string;
	name_ar: string;
	price: number;
}

// ! CRUD

// * Create / Update
export interface IProductDetailsPayload
	extends Pick<
		IProduct,
		| 'name'
		| 'name_ar'
		| 'description'
		| 'description_ar'
		| 'discount_type'
		| 'discount_value'
		| 'maximum_order_quantity'
	> {
	sub_category_id: number;
	price?: number; // ! Only sent on product create
}

// * Variants - Bulk Save
export interface IVariantBulkSavePayload {
	variants: IVariant[];
	variants_group_name: string;
	variants_group_name_ar: string;
}

// * Options - Bulk Save
export interface IOptionGroupsSavePayload {
	variants: Pick<IVariant, 'option_groups' | 'id'>[];
}

// ! CSV ----------------------
// * Products and Variants
// unSerialized file interfaces
export interface IParsedCsvProductItem
	extends Omit<ICsvProductItem, 'product_variants' | 'row_number'>,
		Omit<ICsvProductItemVariant, 'row_number'> {}

export interface IParsedCsvProductRowItem extends IParsedCsvProductItem {
	row_number: number;
}

// serialized interfaces//
export interface ICsvProductItemVariant {
	variant_id?: IVariant['id'];
	variant_name: IVariant['name'];
	variant_name_ar: IVariant['name_ar'];
	variant_price: IVariant['price'];
	variant_status: IVariant['status'];
	variant_barcode: IVariant['barcode'];
	variant_sku: IVariant['sku'];
	row_number: number;
}

export interface ICsvProductItem {
	sub_category_id: IDefaultCategory['id'];
	product_id?: IProduct['id'];
	product_name: IProduct['name'];
	product_name_ar: IProduct['name_ar'];
	product_price: IProduct['price'];
	product_description?: IProduct['description'];
	product_description_ar?: IProduct['description_ar'];
	product_status: IProduct['status'];
	product_maximum_order_quantity: IProduct['maximum_order_quantity'];
	product_discount_value: IProduct['discount_value'];
	product_discount_type: IProduct['discount_type'];
	row_number: number;
	product_variants: ICsvProductItemVariant[];
}

// * Option Groups and Options
export interface IParsedCsvOptionGroupAndOptionsItem
	extends Omit<ICsvOptionGroupItem, 'product_options' | 'row_number'>,
		Omit<ICsvOptionGroupOptionItem, 'row_number'> {}

export interface IParsedCsvOptionGroupAndOptionsRowItem extends IParsedCsvOptionGroupAndOptionsItem {
	row_number: number;
}
// serialized file interfaces
export interface ICsvOptionGroupOptionItem {
	option_id?: IOption['id'];
	option_name: IOption['name'];
	option_name_ar: IOption['name_ar'];
	option_price: IOption['price'];
	option_sort_order: IOption['sort_order'];
	option_status: IOption['status'];
	row_number: number;
}
export interface ICsvOptionGroupItem {
	option_group_id?: number;
	product_id: IProduct['id'];
	product_name: IProduct['name'];
	variant_id: number;
	variant_name: IVariant['name'];
	option_group_name: IOptionGroup['name'];
	option_group_name_ar: IOptionGroup['name_ar'];
	option_group_instructions: IOptionGroup['instructions'];
	option_group_instructions_ar: IOptionGroup['instructions_ar'];
	option_group_limit_min: IOptionGroup['limit_min'];
	option_group_limit_max: IOptionGroup['limit_max'];
	option_group_show_selection_limitation_label: IOptionGroup['show_selection_limitation_label'];
	option_group_sort_order: IOptionGroup['sort_order'];
	option_group_status: IOptionGroup['status'];

	product_options: ICsvOptionGroupOptionItem[];
	row_number: number;
}
