import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNewHttpClient } from 'hooks';
import { getProcessedBannerList } from './helpers';
import { BANNERS_API } from 'configs/api';
import { DEFAULT_EMPTY_VALUE_PLACEHOLDER } from 'configs/common';
import { EBannerType, IBanner } from 'types/api';
import { EStatus, IListResponse } from 'types/common';
import { IPromotionalBannersPreviewProps } from './types';
import Spinner from 'components/Spinner';
import SwimlaneDetailedCard from '../SwimlaneDetailedCard';
import BannerItemCard from './BannerItemCard';
import styles from './PromotionalBannersPreview.module.css';
import { Alert, Empty, Flex, Skeleton, Typography as T, Timeline, TimelineItemProps } from 'antd';
import { ShopOutlined } from '@ant-design/icons';

const FAKE_STORE_POSITION = {
	dot: <ShopOutlined style={{ color: 'black' }} />,
	children: (
		<div className={styles.fake_store_card}>
			<Skeleton.Input
				size='large'
				block
			/>
		</div>
	),
};

const PromotionalBannersPreview: FC<IPromotionalBannersPreviewProps> = ({ onEdit, forceRefreshData }) => {
	const { t: tBanners } = useTranslation('banners');

	// ! http clients
	const fetchHttpClient = useNewHttpClient<IListResponse<IBanner>>();

	// ! states
	const [timeLineItems, setTimeLineItems] = useState<TimelineItemProps[]>([]);

	// ! handlers
	const setTimelineItemsAfterRequestBannerList = (response: IListResponse<IBanner>) => {
		const processedList = getProcessedBannerList(response?.data);

		const newTimeLineList: TimelineItemProps[] = processedList.map(({ position, banners }) => {
			const isStorePosition = !banners.length;

			if (isStorePosition) {
				return FAKE_STORE_POSITION;
			}

			return {
				dot: <T.Text strong>{position}:</T.Text>,
				children: (
					<Flex
						gap={12}
						wrap='wrap'
					>
						{/*Set banner or swimlane*/}
						{banners.map((banner, bannerIndex) =>
							banner.type === EBannerType.PROMOTIONAL ? (
								<BannerItemCard
									key={`${position}_banner_${banner.id}`}
									banner={banner}
									hidden={bannerIndex !== 0}
									onEdit={() => onEdit(banner)}
								/>
							) : (
								<SwimlaneDetailedCard
									key={`${position}_swimlane_${banner.id}`}
									swimlaneId={banner.id}
									cardTitle={`#${banner.id} ${banner.title ?? DEFAULT_EMPTY_VALUE_PLACEHOLDER} | ${
										banner.title_ar ?? DEFAULT_EMPTY_VALUE_PLACEHOLDER
									}`}
									swimlane_vendors={banner.swimlane_vendors}
									onSwimlaneVendorsSave={getBannersList}
									onEditSwimlane={() => onEdit(banner)}
								/>
							)
						)}
					</Flex>
				),
			};
		});

		setTimeLineItems(newTimeLineList);
	};

	const getBannersList = () => {
		const ctrl = new AbortController();

		fetchHttpClient.request(
			{
				requestConfig: BANNERS_API.list({
					per_page: '200',
					status: EStatus.ACTIVE,
					type: [EBannerType.PROMOTIONAL, EBannerType.SWIMLANE],
				}),
				successCallback: setTimelineItemsAfterRequestBannerList,
			},
			ctrl.signal
		);

		// Unsubscribing (cancel the request)
		return () => ctrl.abort();
	};

	// ! effects
	useEffect(() => {
		getBannersList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [forceRefreshData]);

	// ! render
	return (
		<>
			{fetchHttpClient.isLoading && <Spinner defaultAntdSpinner />}

			<Flex
				vertical
				gap='middle'
			>
				<Alert
					showIcon
					type='warning'
					message={tBanners('preview_alert_message_banner_swimlane', { max: null })}
				/>

				<div className='w-100'>
					{timeLineItems?.length > 0 ? (
						<Timeline
							mode='left'
							items={timeLineItems}
							className={styles.banner_order_preview}
						/>
					) : (
						<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
					)}
				</div>
			</Flex>
		</>
	);
};

export default PromotionalBannersPreview;
