import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { useSelector } from 'store';
import { getSelectedCountryIsoTwoCode } from 'store/selectors';
import { useCustomSearchParams, useCustomer, useSearchDataList } from 'hooks';
import { transformCustomerCustomerSupportRequestToTableData } from './helpers';
import { transformCustomerSupportRequestTypesToOptionsList } from 'utils/transformCustomerSupportRequestTypesToOptionsList';
import { CUSTOMER_API, CUSTOMER_SUPPORT_REQUESTS_API } from 'configs/api';
import {
	CUSTOMER_SUPPORT_REQUESTS_STATUS_FILTERS_CONFIG,
	DEFAULT_EMPTY_VALUE_PLACEHOLDER,
	ORDER_CODE_COLUMN_WIDTH,
} from 'configs/common';
import { APP_PERMISSIONS } from 'configs/permissions';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import { DEFAULT_CUSTOMER_CUSTOMER_SUPPORT_REQUESTS_COLUMNS } from './configs';
import { ICustomerSupportRequestTypes } from 'types/api';
import { IFilterItem } from 'types/common';
import { ICustomerCustomerSupportRequestTableData } from './types';
import ActionsMenu, { IActionMenuItem, getActionButtonsColumnWidth } from 'components/ActionsMenu';
import LinkButton from 'components/LinkButton';
import TableWrapper from 'components/Table';
import CustomerSupportRequestStatusTag from 'components/Tags/CustomerSupportRequestStatusTag';
import { ColumnsType } from 'antd/es/table';
import { EyeOutlined } from '@ant-design/icons';

const CustomerSupportRequestsTab: FC = () => {
	const { t: tCommon } = useTranslation();
	const { t: tCustomers } = useTranslation('customers', {
		keyPrefix: 'customer_details.tabs.customer_support_requests',
	});

	const [searchParams] = useCustomSearchParams();

	const { customerId } = useCustomer();

	// ! selectors
	const countryIsoTwoCode = useSelector(getSelectedCountryIsoTwoCode);

	// ! filter params
	const status = searchParams.getAll('status');
	const type = searchParams.getAll('type');

	const { list: typesList, loading: fetchingTypes } = useSearchDataList<ICustomerSupportRequestTypes>({
		configHttp: CUSTOMER_SUPPORT_REQUESTS_API.requestTypesList,
	});

	// ! memos
	const typeOptionList = useMemo<IFilterItem[]>(() => {
		return transformCustomerSupportRequestTypesToOptionsList(typesList);
	}, [typesList]);

	const tableColumns = useMemo<ColumnsType<ICustomerCustomerSupportRequestTableData>>(() => {
		return [
			...DEFAULT_CUSTOMER_CUSTOMER_SUPPORT_REQUESTS_COLUMNS,
			{
				key: 'order_code',
				title: tCustomers('entity.order_code'),
				width: ORDER_CODE_COLUMN_WIDTH,
				filteredValue: null,
				render: (_, { order }) => (
					<LinkButton
						label={order.code}
						permissions={APP_PERMISSIONS.order.view}
						to={generatePath(ABSOLUTE_ROUTES.ORDER_DETAILS, { countryIsoTwoCode, orderId: order.id })}
					/>
				),
			},
			{
				key: 'type',
				title: tCustomers('entity.request_type'),
				dataIndex: ['info', 'request_type_description'],
				filterMultiple: false,
				filteredValue: type || null,
				filters: typeOptionList,
			},
			{
				key: 'refund_type',
				title: tCustomers('entity.refund_type'),
				render: (_, { info }) => info.requested_payment_method || DEFAULT_EMPTY_VALUE_PLACEHOLDER,
				filteredValue: null,
			},
			{
				key: 'status',
				width: 120,
				title: tCustomers('entity.status'),
				render: (_, { info }) => <CustomerSupportRequestStatusTag status={info.status} />,
				filterMultiple: true,
				filters: CUSTOMER_SUPPORT_REQUESTS_STATUS_FILTERS_CONFIG,
				filteredValue: status.length ? status : null,
			},
			{
				key: 'actions',
				title: tCustomers('entity.actions'),
				width: getActionButtonsColumnWidth(1),
				fixed: 'right',
				render: (_, record) => {
					const actions: IActionMenuItem[] = [
						{
							title: tCommon('action_buttons.view_details'),
							icon: <EyeOutlined />,
							type: 'link',
							navigateTo: generatePath(ABSOLUTE_ROUTES.CUSTOMER_SUPPORT_REQUESTS_DETAILS, {
								countryIsoTwoCode,
								requestId: record.id,
							}),
						},
					];
					return <ActionsMenu actions={actions} />;
				},
			},
		];
	}, [countryIsoTwoCode, status, tCommon, tCustomers, type, typeOptionList]);

	return (
		<TableWrapper
			columns={tableColumns}
			isLoading={fetchingTypes}
			searchParam='order_code'
			searchPlaceholder={tCustomers('search_placeholder')}
			requestConfig={CUSTOMER_API.getCustomerCustomerSupportRequestsList(customerId)}
			transformDataToTableData={transformCustomerCustomerSupportRequestToTableData}
		/>
	);
};

export default CustomerSupportRequestsTab;
