import {
	EAssignmentMode,
	EChoiceOption,
	EDeliveryMode,
	EOnOffStatus,
	EStatus,
	Nullable,
	TShortWeekDay,
} from 'types/common';
import { IArea, IAreaCluster } from './areas';
import { ICompany } from './companies';
import { ICountry, ICountryTimezone } from './countries';
import { EDocumentStatus, IRecordTimestamps } from './documents';
import { TIntegrationType } from './integration';
import { IVertical } from './verticals';

// ! enums & types ---------
export type TVendorProductsPerRow = 1 | 2;

export enum EVendorStatus {
	PENDING = 'pending',
	APPROVED = 'approved',
	BLOCKED = 'blocked',
}

export enum EBusinessModel {
	CAM = 'cam', // Customer Acquisition Model
	PCM = 'pcm', // Platform Commission Model
}

export enum EVendorLinkEventStatsEvent {
	CLICK = 'CLICK',
	REDIRECT = 'REDIRECT',
	APP_INSTALL = 'APP_INSTALL',
	APP_FIRST_OPEN = 'APP_FIRST_OPEN',
	APP_RE_OPEN = 'APP_RE_OPEN',
}

export enum EVendorLinkEventStatsPlatform {
	ANDROID = 'ANDROID',
	IOS = 'IOS',
	DESKTOP = 'DESKTOP',
}

export enum EVendorType {
	FOOD = 'food',
	GROCERIES = 'groceries',
	PHARMACY = 'pharmacy',
	ELECTRONICS = 'electronics',
	FLOWERS = 'flowers',
}

// * Branches (aka "Old Stores")
export enum EBranchStatus {
	PENDING = 'pending',
	APPROVED = 'approved',
	BLOCKED = 'blocked',
}

export enum EBranchScheduledOrderSupport {
	ENABLED = 'enabled',
	DISABLED = 'disabled',
}

export enum EBranchPreparationMode {
	DEFAULT = 'default',
	AUTO = 'auto',
}

export enum EIsSubCategory {
	YES = 'yes',
	NO = 'no',
}

export enum EShiftOrigin {
	PLATFORM = 'platform',
	INTEGRATION = 'integration',
}

// ! interfaces ---------

export interface IWebPresence {
	// presence & white label
	slug: string;
	subdomain: string;

	// * web
	available_on_web: EChoiceOption;
	// * mobile
	available_on_mobile: EChoiceOption;
}

export interface IVendorInfo {
	id: number;
	vendor_id: number;
	name: string;
	name_ar: string;
	display_name: string;
	display_name_ar: string;
	description: string;
	description_ar?: string;
	status: EVendorStatus;
	business_model: EBusinessModel;
	products_per_row: TVendorProductsPerRow;
	cam_reg_code?: string;
	store_commission?: number;
	integration_type: TIntegrationType;
	cam_expiration_in_months?: number;
	cam_fee?: number;
	pcm_fee?: number;
	platform_delivery_fee?: number;
	shift_source: EShiftOrigin;
	first_order_delivery_fee_active: EChoiceOption;
	first_order_delivery_fee: number;

	// Presence
	subdomain: IWebPresence['subdomain'];
	available_on_web: IWebPresence['available_on_web'];
	available_on_mobile: IWebPresence['available_on_mobile'];

	// type
	type?: EVendorType;

	// campaigns
	allow_cari_campaign_percentage: EChoiceOption;
	allow_cari_campaign_amount: EChoiceOption;
	allow_cari_campaign_cashback_amount: EChoiceOption;
	allow_cari_campaign_cashback_percentage: EChoiceOption;
	allow_cari_campaign_free_delivery: EChoiceOption;
}

export interface IVendorImages {
	banner: string;
	image: string;
}

interface IVendorContactInfo {
	phone_country_code: string;
	contact_number: string;
}

export interface IVendorActions {
	can_search_product_variant_barcode: boolean;
	can_search_product_variant_sku: boolean;
}

export interface IVendor {
	id: number;
	info: IVendorInfo;
	country_id: ICountry['id'];
	company?: Pick<ICompany, 'id' | 'name'>;
	images: IVendorImages;
	contact_info: IVendorContactInfo;
	verticals: Pick<IVertical, 'id' | 'name'>[];
	is_associated_with_cari_unlimited: EChoiceOption;
	actions: IVendorActions;
}

export interface IGenericVendor {
	id: IVendor['id'];
	image: IVendor['images']['image'];
	name: IVendor['info']['name'];
	name_ar: IVendor['info']['name_ar'];
	status: IVendor['info']['status'];
}

// * Associable Branches
export interface IVendorAssociableBranch {
	id: IBranch['id'];
	name: IBranch['info']['name'];
	name_ar: IBranch['info']['name_ar'];
	is_associated: boolean;
}

export enum EBranchesAssociationPayloadAssociationType {
	ALL = 'all',
	CHOSEN = 'chosen',
	NONE = 'none',
}

export interface IBranchesAssociationPayload {
	remove_ids?: number[];
	add_ids?: number[];
	association_type: EBranchesAssociationPayloadAssociationType;
}

// * Branches
interface IBranchSchedule {
	shift_id: IWorkShift['id'];
	day: string;
	is_opened: boolean;
	opening_hours: string;
	opening_hours_slots: string[];
}

interface IBranchImages {
	logo: string;
	banner: string;
}

interface IBranchLocation {
	address: string;
	country_id: ICountry['id'];
	lat: string;
	lng: string;
	timezone: ICountryTimezone['name'];
	timezone_id: ICountryTimezone['id'];
}

interface IBranchDeliveryInfo {
	fee: number;
	min_order_delivery_time: number;
	max_order_delivery_time: number;
	order_delivery_time: string;
	allow_cash_payment: EChoiceOption;
	in_progress_limit: number;
	driver_attendance_radius: number;
}

interface IBranchRatings {
	total: number;
	average: number;
}

interface IBranchBasicInfo {
	name: string;
	name_ar: string;

	display_name: string;
	display_name_ar: Nullable<string>;

	description: string;
	description_ar: string;

	status: EBranchStatus;
	current_status: EOnOffStatus;

	order_min_amount: number;
	scheduled_order_support: EBranchScheduledOrderSupport;
	preparation_mode: EBranchPreparationMode;
	preparation_time: number;
	delivery_mode: EDeliveryMode;
	delivery_mode_description: string;
	delivery_mode_description_detailed: string;
	assignment_mode: EAssignmentMode;
	scheduled_order_advance_limit: number;

	// presence
	slug: IWebPresence['slug'];
	available_on_web: IWebPresence['available_on_web'];
	available_on_mobile: IWebPresence['available_on_mobile'];
}

interface IBranchContactInfo {
	phone_country_code: Nullable<string>;
	contact_number: Nullable<string>;
}

interface IBranchArea {
	id: IArea['id'];
	name: string;
}

interface IBranchAreaCluster extends Pick<IAreaCluster, 'id' | 'name' | 'name_ar'> {}

export interface IBranch {
	id: number;
	info: IBranchBasicInfo;
	contact_info: IBranchContactInfo;
	images: IBranchImages;
	location: IBranchLocation;
	delivery: IBranchDeliveryInfo;
	ratings: IBranchRatings;
	schedule: IBranchSchedule;
	menu_id: number;
	area: Nullable<IBranchArea>;
	cluster: Nullable<IBranchAreaCluster>;
}

export interface IGenericBranch {
	id: IBranch['id'];
	image: IBranch['images']['logo'];
	name: IBranch['info']['name'];
	name_ar: IBranch['info']['name_ar'];
	status: IBranch['info']['status'];
	vendor_id: IVendor['id'];
}

export interface IBranchListResponse {
	id: IBranch['id'];
	// info
	name: IBranch['info']['name'];
	name_ar: IBranch['info']['name_ar'];
	is_opened: boolean;
	status: IBranch['info']['status'];
	current_status: EOnOffStatus;
	scheduled_order_support: EBranchScheduledOrderSupport;
	average_ratings: number | string;
	// areas
	area: Nullable<IBranch['area']>;
}

export interface IPresenceBranchListResponse extends IWebPresence {
	id: IBranch['id'];
	name: IBranch['info']['name'];
	name_ar: IBranch['info']['name_ar'];
}

export interface IBranchIntegrationSettingsListResponse {
	id: IBranch['id'];
	// info
	name: IBranch['info']['name'];
	name_ar: IBranch['info']['name_ar'];
	status: IBranch['info']['status'];
}

export interface INewBranchPayload {
	// info
	name: string;
	name_ar: string;
	display_name: string;
	display_name_ar: string;
	description?: string;
	description_ar?: string;
	status?: EBranchStatus;
	current_status?: EOnOffStatus;
	order_min_amount?: number;
	delivery_mode: EDeliveryMode;
	assignment_mode: EAssignmentMode;

	scheduled_order_support: EBranchScheduledOrderSupport;

	// location
	lat: string;
	lng: string;
	address: string;
	timezone_id: number;

	preparation_mode: EBranchPreparationMode;
	preparation_time: number;

	// delivery
	delivery_fee?: number;
	min_order_delivery_time: number;
	max_order_delivery_time: number;
	in_progress_limit: number;
	driver_attendance_radius: number;

	// contact_info
	contact_number: string;
	phone_country_code: string;

	area_id?: IArea['id'];
	store_cluster_id?: IAreaCluster['id'];

	allow_cash_payment: EChoiceOption;
	scheduled_order_advance_limit: number;
}

export interface IBranchMenu {
	id: number;
	name: string;
	name_ar: string;
	status: EStatus;
}

// * Deep Link
export interface IVendorDeepLink {
	id: number;
	link: string;
	title: string;
	description: string;
	image?: string;
}

export interface IVendorLinkEventStats {
	count: string;
	event: EVendorLinkEventStatsEvent;
	platform: EVendorLinkEventStatsPlatform;
}

export interface IVendorDeepLinkAnalytics {
	linkEventStats: IVendorLinkEventStats[];
}

// ! CRUD ---------
export interface IVendorPayload {
	id?: number;
	company_id?: number;
	// info
	name: string;
	name_ar: string;
	display_name: string;
	display_name_ar: string;
	description?: string;
	description_ar?: string;
	products_per_row?: number;
	cam_reg_code?: string;
	status?: EVendorStatus;
	business_model: EBusinessModel;
	first_order_delivery_fee_active: EChoiceOption;
	first_order_delivery_fee: number;
	// contact info
	contact_number: string;
	phone_country_code: string;
	// delivery info
	store_commission?: number;
	// verticals
	vertical_ids?: number[];
	// integration / deliverect
	integration_type: TIntegrationType;
	// platform delivery fee
	platform_delivery_fee?: number;
	// cam
	cam_expiration_in_months?: number;
	cam_fee?: number;
	// pcm
	pcm_fee?: number;
	// vendor type
	type?: EVendorType;
	// campaigns
	allow_cari_campaign_percentage: EChoiceOption;
	allow_cari_campaign_amount: EChoiceOption;
	allow_cari_campaign_cashback_amount: EChoiceOption;
	allow_cari_campaign_cashback_percentage: EChoiceOption;
	allow_cari_campaign_free_delivery: EChoiceOption;
}

// * Category
export interface IVendorCategory {
	id: number;
	name: string;
	name_ar: string;
	icon: string;
	status: EStatus;
	sort_order: number;

	// associated menus
	menus: Pick<IMenu, 'id' | 'name'>[];
}

export interface IGenericVendorCategory {
	id: number;
	name: string;
	name_ar: string;
	icon: string;
	status: EStatus;
}

export interface IVendorAddCategoryPayload {
	category_id: number;
	status: EStatus;
}

export interface IVendorAddSubCategoryPayload {
	name: string;
	name_ar: string;
}

export type TVendorAddEditCategoryPayload = IVendorAddCategoryPayload | IVendorAddSubCategoryPayload;

export interface IVendorUpdateCategoryPayload extends Pick<IVendorCategory, 'id' | 'status'> {
	id: number;
	status: EStatus;
}

// * Menus
export enum EMenuType {
	SIMPLE = 'simple',
	COMPLEX = 'complex',
}

// Entity can be Category, SubCategory, Product

export interface ISnoozeMenuEntity {
	start: string;
	end: string;
	is_snoozed: boolean;
}
export interface IMenuEntity {
	id: number;
	name: string;
	name_ar: string;
	image: string;
	status: EStatus;
	products?: IMenuEntity[];
	sub_categories?: IMenuEntity[];

	vendor_product_is_active?: boolean;
	menu_product_is_active?: boolean;

	vendor_category_is_active?: boolean;
	menu_category_is_active?: boolean;

	sort_order: number;
	snooze: ISnoozeMenuEntity;

	// ** Product Specific
	maximum_order_quantity?: number;
}

export interface IMenu {
	id: number;
	name: string;
	name_ar: string;
	type: EMenuType;
	status: EStatus;
	categories: IMenuEntity[];

	stores: Pick<IVendorAssociableBranch, 'id' | 'name'>[];
}

export interface IMenuPatchRequest {
	name: string;
	name_ar: string;
}

export interface IMenuCreateRequest {
	name: string;
	name_ar: string;
	type: EMenuType;
}

export interface IMenuAddProduct {
	products: {
		id: number;
		sort_order: number;
	}[];
	sub_category_id?: number;
}
export interface IMenuAddCategory {
	categories: {
		id: number;
		sort_order: number;
	}[];
	parent_category_id?: number;
}

export interface IMenuTreeCoordinate {
	category_id: number;
	sub_category_id?: number;
	product_id?: number[] | number;
}
export interface IMenuDeleteItemsRequest {
	items: IMenuTreeCoordinate[];
}
export interface IMenuUpdateItemsStatusRequest {
	items: IMenuTreeCoordinate[];
	status: EStatus;
}

export interface IMenuItemsSortRequest {
	sorting: 'products' | 'categories' | 'sub_categories';
	target?: {
		category_id: number;
		sub_category_id?: number;
	};
	ids: number[]; // list item ids in order
}

// * Work Shifts
export interface IWorkShift {
	id: number;
	name: string;
	name_ar: string;

	branches?: IWorkShiftAssociatedBranch[];
}

export interface IWorkShiftAssociatedBranch {
	id: number;
	name: string;
	name_ar: string;
}

export interface IWorkShiftTiming {
	timings: IBranchTiming[];
}
export interface IWorkShiftCreateRequest {
	id: IWorkShift['id'];
	type: 'add' | 'edit';
	name: string;
	name_ar: string;
}

// * Timings
export interface IBranchTimingsSchedule {
	open_time: string;
	close_time: string;
}

export interface IBranchTiming {
	day: TShortWeekDay;
	schedule: IBranchTimingsSchedule[];
}

// * Documents
export interface IVendorDocumentListItemResponse extends IRecordTimestamps {
	id: number;
	name: string;
	name_ar: string;
	document_file: string;
	status: EDocumentStatus;
}

export interface IMenuEntityAssociationPayload {
	association_type: EBranchesAssociationPayloadAssociationType;
	remove_ids?: number[];
	add_ids?: number[];
}

// * Delivery Areas
export enum EStoreDeliveryAreaVehicleType {
	ALL = 'all',
	CAR = 'car', // EVehicleTypes.CAR
	MOTORCYCLE = 'motorcycle', // EVehicleTypes.MOTORCYCLE
}

export interface IVendorBranchDeliveryArea {
	area_id: IArea['id'];
	vehicle_type: EStoreDeliveryAreaVehicleType;
	delivery_fee: Nullable<IBranch['delivery']['fee']>;
	min_order_delivery_time: Nullable<IBranch['delivery']['min_order_delivery_time']>;
	max_order_delivery_time: Nullable<IBranch['delivery']['max_order_delivery_time']>;
}

export interface IVendorBranchDeliveryAreasPatch {
	areas: Array<IVendorBranchDeliveryArea>;
}

// * Deep Link
export interface ICreateVendorDeepLinkInfo extends Pick<IVendorDeepLink, 'title' | 'description'> {}

// * Bank details
export interface IVendorBankInfo {
	account_number: string;
	holder_name: string;
	bank_name: string;
	bank_location: string;
	payment_email: string;
	bic_swift_code: string;
}

export interface IUpdateVendorBankInfo extends IVendorBankInfo {}

// * CITC
export interface ICITCEntryResponse {
	id: string;
	nameAr: string;
	nameEn: string;
}

export interface ICITCListResponse {
	status: boolean;
	errorCodes: number[];
	errorCodesPlain?: string;
	data: ICITCEntryResponse[];
}

export interface ICITCVendorBranchData {
	authority_id: string;
	category_id: string;
	region_id: string;
	city_id: string;
}

// * Presence
export interface IPatchPresenceRequestData {
	slug?: string;
	subdomain?: string;
	available_on_web?: EChoiceOption;
	available_on_mobile?: EChoiceOption;
}

// * Snooze Menu Product

export interface IToggleSnoozeProductPayload extends IMenuTreeCoordinate {
	number_of_minutes: number;
}

export enum EMenuAssociation {
	ASSOCIATED = 'associated',
	UNASSOCIATED = 'unassociated',
}

export interface IMigrateVendorPayload {
	integration_type: string;
}
