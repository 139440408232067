import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';
import { useSelector } from 'store';
import { getSelectedCountryIsoTwoCode } from 'store/selectors';
import { useAuth } from 'hooks';
import { APP_PERMISSIONS } from 'configs/permissions';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import { ISwimlaneDetailedCardProps } from './types';
import LinkButton from 'components/LinkButton';
import { TextSplitter } from 'components/TextSplitter';
import ManageSwimlaneVendors from '../ManageSwimlaneVendors/ManageSwimlaneVendors';
import styles from './SwimlaneDetailedCard.module.css';
import { Button, Card, Empty, Flex, Image, Tooltip, Typography } from 'antd';
import Meta from 'antd/es/card/Meta';
import { EditOutlined } from '@ant-design/icons';
import noImgLogo from 'assets/images/no-image.svg';

const SwimlaneDetailedCard: FC<ISwimlaneDetailedCardProps> = ({
	cardTitle,
	swimlane_vendors = [],
	swimlaneId,
	onSwimlaneVendorsSave,
	onEditSwimlane,
}) => {
	const { hasPermission } = useAuth();
	const { t: tBanners } = useTranslation('banners');

	const canEdit = hasPermission(APP_PERMISSIONS.application_configuration.banners.update);

	// ! states
	const [openManageVendors, setOpenManageVendors] = useState<boolean>(false);

	// ! selectors
	const countryIsoTwoCode = useSelector(getSelectedCountryIsoTwoCode);

	const hasNoVendors = swimlane_vendors?.length === 0;

	// ! handlers
	const onEditCard = () => {
		if (!swimlaneId) return;

		if (onEditSwimlane) {
			onEditSwimlane();
		} else {
			setOpenManageVendors(true);
		}
	};

	const onCloseDrawer = () => {
		setOpenManageVendors(false);
	};

	// ! render
	return (
		<>
			<Card
				size='small'
				title={
					<LinkButton
						label={cardTitle}
						to={`../${swimlaneId}/details`}
					/>
				}
				extra={
					swimlaneId && (
						<Button
							size='small'
							icon={<EditOutlined />}
							disabled={!canEdit}
							onClick={onEditCard}
						/>
					)
				}
				className='w-100'
				styles={{
					body: {
						padding: 0,
					},
				}}
			>
				<Flex justify={hasNoVendors ? 'center' : 'flex-start'}>
					{hasNoVendors && (
						<Empty
							image={Empty.PRESENTED_IMAGE_SIMPLE}
							description={tBanners('card_vendors.no_vendors_selected')}
						/>
					)}
					{!hasNoVendors && (
						<Flex
							gap={16}
							justify='start'
							className={styles.card_body}
						>
							{swimlane_vendors?.map((vendor) => (
								<Link
									key={vendor.vendor_id}
									to={generatePath(ABSOLUTE_ROUTES.VENDOR_DETAILS, {
										countryIsoTwoCode,
										vendorId: vendor.vendor_id,
									})}
								>
									<Card
										hoverable
										className={styles.vendors_card}
										cover={
											<Flex
												align='center'
												justify='center'
												className={styles.vendors_card_cover}
											>
												<Image
													src={vendor.image}
													fallback={noImgLogo}
													height={'100%'}
													width={'100%'}
													placeholder={
														<Image
															preview={false}
															src={noImgLogo}
															width={100}
														/>
													}
													loading='lazy'
													preview={false}
													alt='vendor_img'
												/>
											</Flex>
										}
										styles={{
											body: {
												padding: 12,
											},
										}}
									>
										<Meta
											title={
												<Tooltip
													title={
														<>
															#{vendor.vendor_id} - {vendor.name} <TextSplitter />{' '}
															{vendor.name_ar}
														</>
													}
												>
													<Typography.Text>{`#${vendor.vendor_id} - ${vendor.name}`}</Typography.Text>
												</Tooltip>
											}
											description={<Typography.Text ellipsis>{vendor.name_ar}</Typography.Text>}
										/>
									</Card>
								</Link>
							))}
						</Flex>
					)}
				</Flex>
			</Card>

			{swimlaneId && onSwimlaneVendorsSave && (
				<ManageSwimlaneVendors
					open={openManageVendors}
					onClose={onCloseDrawer}
					bannerId={swimlaneId}
					vendors={swimlane_vendors}
					onSaveVendors={onSwimlaneVendorsSave}
				/>
			)}
		</>
	);
};

export default SwimlaneDetailedCard;
