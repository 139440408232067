import { useMemo, useState } from 'react';
import { Nullable } from 'types/common';
import { ISortableBaseItem, ISortableListProps } from './types';
import { DragHandle, SortableItem } from './SortableItem';
import { SortableOverlay } from './SortableOverlay';
import { List } from 'antd';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import type { Active } from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import VirtualList from 'rc-virtual-list';

export default function SortableList<T extends ISortableBaseItem>({
	items,
	onChange,
	renderItem,
	listHeight = 0,
}: ISortableListProps<T>) {
	const [active, setActive] = useState<Nullable<Active>>(null);

	const activeItem = useMemo(() => items.find((item) => item.id === active?.id), [active, items]);
	const sensors = useSensors(useSensor(PointerSensor));
	const listPadding = listHeight > 0 ? 20 : listHeight;
	const listHeightWithPadding = listHeight > 0 ? listHeight : undefined;

	const resetActiveItem = () => setActive(null);

	// ! render
	return (
		<DndContext
			sensors={sensors}
			onDragStart={({ active }) => {
				setActive(active);
			}}
			onDragEnd={({ active, over }) => {
				if (over && active.id !== over?.id) {
					const activeIndex = items.findIndex(({ id }) => id === active.id);
					const overIndex = items.findIndex(({ id }) => id === over.id);

					onChange(arrayMove(items, activeIndex, overIndex));
				}
				resetActiveItem();
			}}
			onDragCancel={resetActiveItem}
		>
			{/* Set the padding for when we really want used the virtual list, so it does not break other layout  */}
			<div style={{ paddingInlineStart: listPadding, paddingInlineEnd: listPadding }}>
				<SortableContext items={items}>
					<VirtualList
						itemHeight={60}
						height={listHeightWithPadding}
						data={items}
						itemKey={(item) => item.id}
					>
						{(item) => (
							<List.Item key={item.id}>{renderItem(item, item.id === active?.id, active)}</List.Item>
						)}
					</VirtualList>
				</SortableContext>
				<SortableOverlay>{activeItem ? renderItem(activeItem, true, active) : null}</SortableOverlay>
			</div>
		</DndContext>
	);
}

SortableList.Item = SortableItem;
SortableList.DragHandle = DragHandle;
