import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store';
import { DEFAULT_TAG_STYLES } from 'configs/common';
import { ESupportedLanguages } from 'types/common';
import { IBannerItemCardProps } from './types';
import LinkButton from 'components/LinkButton';
import styles from './PromotionalBannersPreview.module.css';
import { Button, Card, Flex, Image, Tag } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import noImgLogo from 'assets/images/no-image.svg';

const CARD_IMG_HEIGHT = 90;
const CARD_WIDTH = CARD_IMG_HEIGHT * 4; // ratio 4:1

const BannerItemCard: FC<IBannerItemCardProps> = ({ banner, hidden = false, onEdit }) => {
	const { t: tCommon } = useTranslation('common');

	// ! selectors
	const selectedLanguage = useSelector((state) => state.settings.language);

	// ! memos
	const bannerImg = useMemo(() => {
		let bannerImg = banner.image; // EN by default

		if (selectedLanguage === ESupportedLanguages.ARABIC && banner.image_ar?.length > 0) {
			bannerImg = banner.image_ar;
		}

		return bannerImg;
	}, [banner, selectedLanguage]);

	// ! render
	const opacity = hidden ? 0.6 : 1;
	const bannerName = selectedLanguage === ESupportedLanguages.ARABIC ? banner.name_ar : banner.name;

	return (
		<Card
			size='small'
			style={{
				minWidth: CARD_WIDTH,
				maxWidth: CARD_WIDTH,
			}}
			styles={{
				body: { opacity, padding: 0 },
			}}
			title={
				<LinkButton
					label={`#${banner.id} ${bannerName}`}
					to={`../${banner.id}/details`}
				/>
			}
			extra={
				<Flex>
					{hidden && (
						<Tag
							color='gold'
							style={DEFAULT_TAG_STYLES}
						>
							{tCommon('on_off_switch.hidden')}
						</Tag>
					)}
					<Button
						size='small'
						icon={<EditOutlined />}
						onClick={onEdit}
					/>
				</Flex>
			}
		>
			<Image
				preview={false}
				alt='banner_image'
				height={CARD_IMG_HEIGHT}
				src={bannerImg || noImgLogo}
				className={styles.banner_img}
			/>
		</Card>
	);
};

export default memo(BannerItemCard);
