import { AxiosRequestConfig } from 'axios';
import { THttpRequestConfig, THttpRequestParams } from 'hooks';
import { IVertical, IVerticalAddVendorsPayload, IVerticalCreatePayload, IVerticalUpdatePayload } from 'types/api';

export const VERTICALS_API = {
	// ! CRUD
	list: (name?: string, params?: THttpRequestParams): AxiosRequestConfig => ({
		url: 'operations/app-management/verticals',
		method: 'GET',
		params: {
			...params,
			name,
		},
	}),
	genericList: (name?: IVertical['name'], params?: THttpRequestParams): THttpRequestConfig => ({
		url: '/operations/app-management/verticals/generic',
		method: 'GET',
		params: {
			...params,
			name,
		},
	}),
	create: (data: IVerticalCreatePayload): THttpRequestConfig => ({
		url: 'operations/app-management/verticals',
		method: 'POST',
		data,
	}),
	read: (id: IVertical['id']): THttpRequestConfig => ({
		url: `operations/app-management/verticals/${id}`,
		method: 'GET',
	}),
	update: (id: IVertical['id'], data: Partial<IVerticalUpdatePayload>): THttpRequestConfig => ({
		url: `operations/app-management/verticals/${id}`,
		method: 'PATCH',
		data,
	}),
	delete: (id: IVertical['id']): THttpRequestConfig => ({
		url: `operations/app-management/verticals/${id}`,
		method: 'DELETE',
	}),

	// ! Areas
	getVerticalAreas: (verticalId: IVertical['id']): THttpRequestConfig => ({
		url: `operations/app-management/verticals/${verticalId}/areas`,
		method: 'GET',
	}),

	// ! Vertical Image
	uploadImage: (verticalId: IVertical['id'], data: FormData): THttpRequestConfig => ({
		url: `operations/app-management/verticals/${verticalId}/image`,
		method: 'POST',
		data,
	}),

	// ! Sub Verticals
	getSubVerticals: (verticalId: number, name?: string, params?: THttpRequestParams): AxiosRequestConfig => ({
		url: 'operations/app-management/verticals',
		method: 'GET',
		params: {
			...params,
			parent_id: verticalId,
			name,
		},
	}),

	// ! Vendor utils
	associableVerticals: (name?: IVertical['name']): THttpRequestConfig => ({
		url: `/operations/app-management/verticals/vendor-associable`,
		method: 'GET',
		params: { name },
	}),

	// ! Vertical Vendors
	getVendors: (verticalId: IVertical['id']): THttpRequestConfig => ({
		url: `operations/app-management/verticals/${verticalId}/vendors`,
		method: 'GET',
	}),

	addPremiumVendor: (verticalId: IVertical['id'], vendor_id: number): THttpRequestConfig => ({
		url: `operations/app-management/verticals/${verticalId}/add-premium-vendor`,
		method: 'POST',
		data: {
			vendor_id,
		},
	}),

	addVendors: (verticalId: IVertical['id'], data: IVerticalAddVendorsPayload): THttpRequestConfig => ({
		url: `operations/app-management/verticals/${verticalId}/add-vendors`,
		method: 'POST',
		data,
	}),

	removeVendor: (verticalId: IVertical['id'], data: IVerticalAddVendorsPayload): THttpRequestConfig => ({
		url: `operations/app-management/verticals/${verticalId}/remove-vendors`,
		method: 'POST',
		data,
	}),
};
