import { IBanner } from 'types/api';
import { IProcessedPreviewList } from './types';

export const getProcessedBannerList = (bannerList: IBanner[]): IProcessedPreviewList[] => {
	const bannerListByPositions: Record<React.Key, IBanner[]> = {};

	// * 1 - set the banner list by positions
	bannerList.forEach((banner) => {
		if (!bannerListByPositions[banner.position]) {
			bannerListByPositions[banner.position] = [];
		}

		bannerListByPositions[banner.position].push(banner);
	});

	// * 2 - build the final list, inserting the "fake" stores
	const bannerPositionList = Object.keys(bannerListByPositions).map((b) => +b);
	const maxBannerPosition = Math.max.apply(Math, bannerPositionList);

	const defaultStoresPerPage = 10;
	const finalList: IProcessedPreviewList[] = [];
	const lastPositionIndex = Math.max.apply(Math, [maxBannerPosition + 1, defaultStoresPerPage]);

	for (let currentPosition = 0; currentPosition < lastPositionIndex; currentPosition++) {
		// insert banner
		if (bannerListByPositions[currentPosition]) {
			finalList.push({
				position: currentPosition,
				banners: bannerListByPositions[currentPosition],
			});
			// insert fake store
		} else {
			finalList.push({
				position: currentPosition,
				banners: [],
			});
		}
	}

	// * 3 - return the final list
	return finalList;
};
