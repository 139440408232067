import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { BannerProvider } from 'hooks/useBanner';
import { TRouteObject } from 'configs/routes';

const BannerDetailsPage = lazy(() => import('pages/Banners/BannerDetailsPage'));

export const BANNER_DETAILS_ROUTE: TRouteObject = {
	path: ':bannerId',
	element: <BannerProvider />,
	children: [
		{
			index: true,
			element: (
				<Navigate
					to='details'
					replace
				/>
			),
		},
		{
			path: 'details',
			element: <BannerDetailsPage />,
		},
	],
};
