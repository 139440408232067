import { FC, ReactNode, memo, useEffect, useMemo, useState } from 'react';
import { DeviceFrameset } from 'react-device-frameset';
import { useTranslation } from 'react-i18next';
import { useGeneralSettings, useNewHttpClient } from 'hooks';
import { BANNERS_API } from 'configs/api';
import { EGeneralSettingsKeys, IBanner } from 'types/api';
import { EStatus, IListResponse } from 'types/common';
import { IHighlightedBannersPreviewProps } from './types';
import Spinner from 'components/Spinner';
import { EBannerTypeTabs } from 'pages/Banners/types';
import { CarouselBannersPreviewer } from './CarouselBannersPreviewer';
import styles from './HighlightedBannersPreview.module.css';
import { Alert, Avatar, Checkbox, Flex, Skeleton, Typography as T } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { HeartOutlined, UserOutlined } from '@ant-design/icons';
import deliveryIcon from 'assets/svgs/delivery-dining.svg';

const HighlightedBannersPreview: FC<IHighlightedBannersPreviewProps> = ({ type }) => {
	const highlightedBannersSettingsSearch = 'highlighted_banners';
	const { isLoading: generalSettingsLoading, getSettingValue } = useGeneralSettings(highlightedBannersSettingsSearch);

	const activeHighlightedBannersLimitSettingValue = getSettingValue(
		EGeneralSettingsKeys.ACTIVE_HIGHLIGHTED_BANNERS_LIMIT
	);
	const highlightedBannersRotationPeriodSettingValue = getSettingValue(
		EGeneralSettingsKeys.HIGHLIGHTED_BANNERS_ROTATION_PERIOD
	);

	const highlightedBannersRotationPeriod =
		highlightedBannersRotationPeriodSettingValue !== null ? +highlightedBannersRotationPeriodSettingValue : 2000;

	const { t: tBanners } = useTranslation('banners');

	// ! state
	const [bannerList, setBannerList] = useState<IBanner[]>([]);
	const [isLandscape, setIsLandscape] = useState<boolean>(false);

	// ! memos
	const activeBanners = useMemo(() => {
		const sortByPosition = (a: IBanner, b: IBanner) => a.position - b.position;

		return bannerList.filter((banner) => banner.status === EStatus.ACTIVE).sort(sortByPosition);
	}, [bannerList]);

	// ! http clients
	const fetchHttpClient = useNewHttpClient<IListResponse<IBanner>>();

	// ! effects
	// * fetch banners
	useEffect(() => {
		fetchHttpClient.request({
			requestConfig: BANNERS_API.list({ type, per_page: '50', page: '1' }),
			successCallback: ({ data }) => setBannerList(data),
		});
	}, [type]); // eslint-disable-line react-hooks/exhaustive-deps

	// ! handlers
	const onToggleLandscape = (ev: CheckboxChangeEvent) => {
		setIsLandscape(ev.target.checked);
	};

	// ! render
	if (generalSettingsLoading || fetchHttpClient.isLoading) {
		return <Spinner defaultAntdSpinner />;
	}

	const SkeletonByBannerType: Record<EBannerTypeTabs, ReactNode> = {
		[EBannerTypeTabs.HIGHLIGHTED_HOME]: (
			<>
				<Skeleton.Input
					size='large'
					block
				/>
				<Skeleton.Input
					size='large'
					block
				/>

				<CarouselBannersPreviewer
					banners={activeBanners}
					imgSize={isLandscape ? 'large' : 'small'}
					rotationPeriod={highlightedBannersRotationPeriod}
				/>

				<Skeleton.Input
					size='large'
					block
					className={styles.fake_vendor_skeleton}
				/>
				<Skeleton.Input
					size='large'
					block
					className={styles.fake_vendor_skeleton}
				/>
				<Skeleton.Input
					size='large'
					block
					className={styles.fake_vendor_skeleton}
				/>
			</>
		),
		[EBannerTypeTabs.HIGHLIGHTED_ACCOUNT]: (
			<>
				<div className={styles.fake_account_skeleton}>
					<Avatar
						size={60}
						icon={<UserOutlined />}
					/>
				</div>

				<CarouselBannersPreviewer
					banners={activeBanners}
					imgSize={isLandscape ? 'large' : 'small'}
					rotationPeriod={highlightedBannersRotationPeriod}
				/>

				<div className={styles.fake_account_my_orders_skeleton}>
					<Flex gap='small'>
						<img
							width={16}
							height={16}
							src={deliveryIcon}
							alt='motorcycle-icon'
						/>
						<T.Text type='secondary'>{tBanners('device_skeletons.my_orders')}</T.Text>
					</Flex>
				</div>

				<div className={styles.fake_account_my_favorites_skeleton}>
					<Flex gap='small'>
						<HeartOutlined />
						<T.Text type='secondary'>{tBanners('device_skeletons.my_favorites')}</T.Text>
					</Flex>
				</div>

				<T.Text type='secondary'>{tBanners('device_skeletons.my_account')}</T.Text>
				<Skeleton.Input
					size='large'
					block
				/>
				<Skeleton.Input
					size='large'
					block
				/>
			</>
		),
		[EBannerTypeTabs.PROMOTIONAL_SWIMLANE]: null,
	};

	return (
		<Flex
			vertical
			gap='large'
		>
			<Flex
				gap='large'
				align='center'
				justify='space-between'
			>
				<Alert
					showIcon
					type='warning'
					className='w-100'
					message={
						<T.Text>
							{tBanners('preview_alert_message', {
								max: activeHighlightedBannersLimitSettingValue
									? `(max: ${activeHighlightedBannersLimitSettingValue})`
									: '',
							})}
							&nbsp;
							<strong>
								{tBanners('entity.rotation_period', { period: highlightedBannersRotationPeriod })}
							</strong>
						</T.Text>
					}
				/>
			</Flex>

			{/* DEVICE FRAME */}
			<Flex
				vertical
				gap='small'
				align='center'
			>
				<Checkbox onChange={onToggleLandscape}>{tBanners('landscape_preview')}</Checkbox>

				<DeviceFrameset
					color='silver'
					device='iPhone 8 Plus'
					landscape={isLandscape}
				>
					<Flex
						vertical
						gap='small'
						className={styles.device_skeleton_container}
					>
						{SkeletonByBannerType[type]}
					</Flex>
				</DeviceFrameset>
			</Flex>
		</Flex>
	);
};

export default memo(HighlightedBannersPreview);
