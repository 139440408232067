import { FC, ReactNode, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { EVerticalType } from 'types/api';
import { Flex, Tooltip } from 'antd';
import { ApartmentOutlined, AppstoreOutlined, BorderOutlined, StarFilled } from '@ant-design/icons';

interface IVerticalTypeProps {
	type: EVerticalType;
	onlyIcon?: boolean;
}

const VerticalType: FC<IVerticalTypeProps> = ({ type, onlyIcon = false }) => {
	const { t: tVerticals } = useTranslation('verticals');

	const config: Record<EVerticalType, ReactNode> = {
		[EVerticalType.PARENT]: <ApartmentOutlined />,
		[EVerticalType.PREMIUM]: <StarFilled style={{ color: 'var(--ant-primary-color)' }} />,
		[EVerticalType.INDEPENDENT]: <BorderOutlined />,
		[EVerticalType.CHILD]: <AppstoreOutlined />,
	};

	const typeDescriptionTooltipTranslation = onlyIcon ? (
		<Flex>
			{tVerticals(`enums.type.${type}`)} - {tVerticals(`enums.type_description.${type}`)}
		</Flex>
	) : (
		tVerticals(`enums.type_description.${type}`)
	);

	return (
		<Tooltip title={typeDescriptionTooltipTranslation}>
			<Flex gap='small'>
				{config[type]}
				{!onlyIcon && tVerticals(`enums.type.${type}`)}
			</Flex>
		</Tooltip>
	);
};

export default memo(VerticalType);
