import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { VendorMenuProvider } from 'hooks/Menu/VendorMenuProvider';
import { BranchProvider } from 'hooks/useBranch';
import { ProductProvider } from 'hooks/useProduct';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import ListParamsRouteWrapper from 'components/ListParamsRouteWrapper';
import TranslatedLabel from 'components/TranslatedLabel';
import { VENDOR_BRANCH_MENUS_ROUTES } from 'pages/Vendors/VendorDetails/Tabs/Branches/BranchDetails/Tabs/Menus/routes.vendor_branch_menus';
import { EBranchTabsKeys } from 'pages/Vendors/VendorDetails/Tabs/Branches/BranchDetails/types';
import { BRANCH_DETAILS_ROUTES } from '../VendorDetails/Tabs/Branches/BranchDetails/configs';
import { EProductTabsKeys, PRODUCT_DETAILS_TAB_ROUTES } from '../VendorDetails/Tabs/Products/routes.product-tabs';
import { EVendorTabsKeys } from '../VendorDetails/Tabs/types';
import {
	ApiOutlined,
	BankOutlined,
	BlockOutlined,
	BorderlessTableOutlined,
	FileDoneOutlined,
	FileTextOutlined,
	InfoOutlined,
	LinkOutlined,
	MenuOutlined,
	ScheduleOutlined,
	ShopOutlined,
	UsergroupAddOutlined,
} from '@ant-design/icons';

// ! Lazy module imports
const AddEditBranch = lazy(() => import('../VendorDetails/Tabs/Branches/AddEditBranch'));
const BranchPageLayout = lazy(() => import('../VendorDetails/Tabs/Branches/BranchDetails'));
const MenuList = lazy(() => import('../VendorDetails/Tabs/Menu'));
const MenuDetails = lazy(() => import('../VendorDetails/Tabs/Menu/MenuDetails'));
const WorkShiftsDetailsContent = lazy(() => import('../VendorDetails/Tabs/WorkShifts'));
const PresenceTabContent = lazy(() => import('../VendorDetails/Tabs/Presence/PresenceTabContent'));
const IntegrationVendorTabContent = lazy(
	() => import('../VendorDetails/Tabs/Integrations/IntegrationVendorTabContent')
);

const ProductList = lazy(() => import('pages/Vendors/VendorDetails/Tabs/Products/ProductList'));
const ProductPageLayout = lazy(
	() => import('pages/Vendors/VendorDetails/Tabs/Products/ProductDetails/ProductPageLayout')
);
const ProductDetailsTab = lazy(
	() => import('pages/Vendors/VendorDetails/Tabs/Products/ProductDetails/Tabs/ProductDetailsTab')
);

const WorkScheduleList = lazy(() => import('pages/Vendors/VendorDetails/Tabs/WorkShifts/WorkShiftList'));
const BranchesTableList = lazy(() => import('pages/Vendors/VendorDetails/Tabs/Branches/BranchesTableList'));
const InvoiceList = lazy(() => import('pages/Invoices'));

const BankDetailsTabContent = lazy(() => import('../VendorDetails/Tabs/BankDetails'));
const CategoriesTabContent = lazy(() => import('../VendorDetails/Tabs/Categories'));
const DeepLinkTabContent = lazy(() => import('../VendorDetails/Tabs/DeepLink'));
const DetailsTabContent = lazy(() => import('../VendorDetails/Tabs/Details'));
const DocumentsTabContent = lazy(() => import('../VendorDetails/Tabs/Documents'));
const StaffTabContent = lazy(() => import('../VendorDetails/Tabs/Staff'));

export const VENDOR_DETAILS_TAB_ROUTES: Record<EVendorTabsKeys, TRouteObject> = {
	[EVendorTabsKeys.DETAILS]: {
		path: EVendorTabsKeys.DETAILS,
		element: <DetailsTabContent />,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='vendors'
					i18nKey={`vendor_details.tabs.${EVendorTabsKeys.DETAILS}.title`}
				/>
			),
			icon: <InfoOutlined />,
			permissions: [APP_PERMISSIONS.vendor.view],
		},
	},
	[EVendorTabsKeys.CATEGORIES]: {
		path: EVendorTabsKeys.CATEGORIES,
		element: <CategoriesTabContent />,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='vendors'
					i18nKey={`vendor_details.tabs.${EVendorTabsKeys.CATEGORIES}.title`}
				/>
			),
			icon: <BlockOutlined />,
			permissions: [APP_PERMISSIONS.vendor.category.view],
		},
	},
	[EVendorTabsKeys.PRODUCTS]: {
		path: EVendorTabsKeys.PRODUCTS,
		element: <ListParamsRouteWrapper key='products-management' />,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='vendors'
					i18nKey={`vendor_details.tabs.${EVendorTabsKeys.PRODUCTS}.title`}
				/>
			),
			translationKey: EVendorTabsKeys.PRODUCTS,
			icon: <BlockOutlined />,
			permissions: [APP_PERMISSIONS.vendor.product.view],
		},
		children: [
			{
				index: true,
				element: <ProductList />,
				handle: {
					permissions: [],
					showOnNav: false,
				},
			},
			{
				path: 'create',
				element: (
					<ProductProvider>
						<ProductPageLayout />
					</ProductProvider>
				),
				handle: {
					showOnNav: false,
					permissions: [APP_PERMISSIONS.vendor.product.add],
				},
				children: [
					{
						index: true,
						element: <ProductDetailsTab isCreating={true} />,
					},
				],
			},
			{
				path: ':productId',
				element: (
					<ProductProvider>
						<ProductPageLayout />
					</ProductProvider>
				),
				handle: {
					showOnNav: false,
					permissions: [APP_PERMISSIONS.vendor.product.view],
				},
				children: [
					{
						index: true,
						element: (
							<Navigate
								replace
								to={EProductTabsKeys.DETAILS}
							/>
						),
					},
					...PRODUCT_DETAILS_TAB_ROUTES,
				],
			},
		],
	},
	[EVendorTabsKeys.MENUS]: {
		path: EVendorTabsKeys.MENUS,
		element: <ListParamsRouteWrapper key='menus-management' />,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='vendors'
					i18nKey={`vendor_details.tabs.${EVendorTabsKeys.MENUS}.title`}
				/>
			),
			icon: <MenuOutlined />,
			permissions: [APP_PERMISSIONS.vendor.menu.view],
		},
		children: [
			{
				index: true,
				element: <MenuList />,
			},
			{
				path: ':menuId',
				element: (
					<VendorMenuProvider>
						<MenuDetails />
					</VendorMenuProvider>
				),
			},
		],
	},
	[EVendorTabsKeys.WORKING_SHIFTS]: {
		path: EVendorTabsKeys.WORKING_SHIFTS,
		element: <ListParamsRouteWrapper key='work-shift-management' />,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='vendors'
					i18nKey={`vendor_details.tabs.${EVendorTabsKeys.WORKING_SHIFTS.replaceAll('-', '_')}.title`}
				/>
			),
			icon: <ScheduleOutlined />,
			permissions: [APP_PERMISSIONS.vendor.shift.view],
		},
		children: [
			{
				index: true,
				element: <WorkScheduleList />,
			},
			{
				path: ':workShiftId',
				element: <WorkShiftsDetailsContent />,
			},
		],
	},
	[EVendorTabsKeys.BRANCHES]: {
		path: EVendorTabsKeys.BRANCHES,
		element: <ListParamsRouteWrapper key='branch-management' />,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='vendors'
					i18nKey={`vendor_details.tabs.${EVendorTabsKeys.BRANCHES}.title`}
				/>
			),
			translationKey: EVendorTabsKeys.BRANCHES,
			icon: <ShopOutlined />,
			permissions: [APP_PERMISSIONS.vendor.store.view],
		},
		children: [
			{
				index: true,
				element: <BranchesTableList />,
			},
			{
				path: ':branchId',
				element: (
					<BranchProvider>
						<BranchPageLayout />
					</BranchProvider>
				),
				children: [
					{
						index: true,
						element: (
							<Navigate
								to={EBranchTabsKeys.DETAILS}
								replace
							/>
						),
					},
					{
						path: 'edit',
						element: <AddEditBranch />,
					},
					{
						path: EBranchTabsKeys.MENUS,
						children: VENDOR_BRANCH_MENUS_ROUTES,
					},

					...BRANCH_DETAILS_ROUTES,
				],
			},
			{
				path: 'add',
				element: <AddEditBranch />,
			},
		],
	},
	[EVendorTabsKeys.INVOICES]: {
		path: EVendorTabsKeys.INVOICES,
		element: <InvoiceList />,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='vendors'
					i18nKey={`vendor_details.tabs.${EVendorTabsKeys.INVOICES}.title`}
				/>
			),
			icon: <FileTextOutlined />,
			permissions: [APP_PERMISSIONS.vendor.invoices.view],
		},
	},
	[EVendorTabsKeys.STAFF]: {
		path: EVendorTabsKeys.STAFF,
		element: <StaffTabContent />,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='vendors'
					i18nKey={`vendor_details.tabs.${EVendorTabsKeys.STAFF}.title`}
				/>
			),
			icon: <UsergroupAddOutlined />,
			permissions: [APP_PERMISSIONS.vendor.vendor_user.view],
		},
	},
	[EVendorTabsKeys.DOCUMENTS]: {
		path: EVendorTabsKeys.DOCUMENTS,
		element: <DocumentsTabContent />,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='vendors'
					i18nKey={`vendor_details.tabs.${EVendorTabsKeys.DOCUMENTS}.title`}
				/>
			),
			icon: <FileDoneOutlined />,
			permissions: [APP_PERMISSIONS.vendor.document.view],
		},
	},
	[EVendorTabsKeys.BANK_INFO]: {
		path: EVendorTabsKeys.BANK_INFO,
		element: <BankDetailsTabContent />,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='vendors'
					i18nKey={`vendor_details.tabs.${EVendorTabsKeys.BANK_INFO.replaceAll('-', '_')}.title`}
				/>
			),
			icon: <BankOutlined />,
			permissions: [APP_PERMISSIONS.vendor.bank_info.view],
		},
	},
	[EVendorTabsKeys.DEEP_LINK]: {
		path: EVendorTabsKeys.DEEP_LINK,
		element: <DeepLinkTabContent />,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='vendors'
					i18nKey={`vendor_details.tabs.${EVendorTabsKeys.DEEP_LINK.replaceAll('-', '_')}.title`}
				/>
			),
			icon: <LinkOutlined />,
			permissions: [APP_PERMISSIONS.vendor.deep_link.view],
		},
	},
	[EVendorTabsKeys.PRESENCE]: {
		path: EVendorTabsKeys.PRESENCE,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='vendors'
					i18nKey={`vendor_details.tabs.${EVendorTabsKeys.PRESENCE}.title`}
				/>
			),
			icon: <BorderlessTableOutlined />,
			permissions: [APP_PERMISSIONS.vendor.presence.view],
		},
		element: <PresenceTabContent />,
	},
	[EVendorTabsKeys.INTEGRATION]: {
		path: EVendorTabsKeys.INTEGRATION,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='vendors'
					i18nKey={`vendor_details.tabs.${EVendorTabsKeys.INTEGRATION}.title`}
				/>
			),
			icon: <ApiOutlined />,
		},
		element: <IntegrationVendorTabContent />,
	},
};

export const VENDOR_TAB_ROUTES: TRouteObject[] = Object.values(VENDOR_DETAILS_TAB_ROUTES);
