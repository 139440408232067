import { FC, memo } from 'react';
import { useSelector } from 'store';
import { IBanner } from 'types/api';
import { ESupportedLanguages } from 'types/common';
import { ICarouselBannersPreviewerProps } from './types';
import styles from './CarouselBannersPreviewer.module.css';
import { Carousel, Empty, Image } from 'antd';

const CarouselBannersPreviewer: FC<ICarouselBannersPreviewerProps> = ({
	banners,
	rotationPeriod,
	imgSize = 'small',
}) => {
	// ! selectors
	const selectedLanguage = useSelector((state) => state.settings.language);

	// ! helpers
	const getBannerImg = (banner: IBanner) => {
		let bannerImg = banner.image; // EN by default

		if (selectedLanguage === ESupportedLanguages.ARABIC && banner.image_ar?.length > 0) {
			bannerImg = banner.image_ar;
		}

		return bannerImg;
	};

	// ! render
	return (
		<div id='carousel_previewer'>
			{banners?.length > 0 ? (
				<Carousel
					infinite
					autoplay
					autoplaySpeed={rotationPeriod}
					className={styles.banners_carousel}
				>
					{banners?.map((banner) => (
						<Image
							key={banner.id}
							preview={false}
							alt='banner_image'
							src={getBannerImg(banner)}
							rootClassName={[
								styles.banner_image,
								imgSize === 'large' ? styles.large : styles.small,
							].join(' ')}
						/>
					))}
				</Carousel>
			) : (
				<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
			)}
		</div>
	);
};

export default memo(CarouselBannersPreviewer);
