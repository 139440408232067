import { EStatus, Nullable } from 'types/common';
import { IArea } from './areas';
import { IVendor } from './vendors';

export enum EVerticalType {
	INDEPENDENT = 'independent',
	PREMIUM = 'premium',
	PARENT = 'parent',
	CHILD = 'child',
}

// ! interfaces
export interface IVerticalAreas {
	area_ids: IArea['id'][];
}

export interface IVerticalVendor {
	id: IVendor['id'];
	name: IVendor['info']['name'];
}

export interface IPremiumVendor {
	id: IVendor['id'];
	name: string;
}

export interface IVertical {
	id: number;
	name: string;
	name_ar: string;
	internal_name: string;
	type: EVerticalType;
	parent_id: Nullable<IVertical['id']>;
	image: string;
	status: EStatus;
	sort_order: number;
	associated_sub_verticals?: number[];

	total_sub_verticals: string;
	total_vendors: string;

	// for the Premium vertical
	premium_vendor?: IPremiumVendor;
}

export interface IGenericVertical {
	id: IVertical['id'];
	name: IVertical['name'];
	name_ar: IVertical['name_ar'];
}

// * CRUD
export interface IVerticalCreatePayload {
	vendor_id: IVendor['id'];
	name: IVertical['name'];
	name_ar: IVertical['name_ar'];
	internal_name: IVertical['internal_name'];
	type: IVertical['type'];
	parent_id?: number;
	sort_order: IVertical['sort_order'];
	status?: IVertical['status'];
}

export interface IVerticalUpdatePayload {
	name?: IVertical['name'];
	name_ar?: IVertical['name_ar'];
	internal_name?: IVertical['internal_name'];
	sort_order?: IVertical['sort_order'];
	status?: IVertical['status'];
	area_ids?: IVerticalAreas['area_ids'];
}

export interface IVerticalAddVendorsPayload {
	vendor_ids: IVendor['id'][];
}
