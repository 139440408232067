import { useCallback, useState } from 'react';
import { useNewHttpClient } from 'hooks';
import { getMapBounds } from './helpers';
import { debounce } from 'utils/debounce';
import { LIVE_TRACKING_API } from 'configs/api';
import { SEARCH_DEBOUNCE_TIMER } from 'configs/common';
import { IBranchesLocationData, IBranchesLocationResponse } from 'types/api';

export const useCachedBranchList = () => {
	const [branchMap, setBranchMap] = useState<Record<IBranchesLocationData['id'], IBranchesLocationData>>({});

	// ! http clients
	const branchesHttpClient = useNewHttpClient<IBranchesLocationResponse>();

	// ! handlers
	const addNewBranchesToMap = (data: IBranchesLocationData[]) => {
		setBranchMap((prevBranchMap) => {
			const newBranchMap = { ...prevBranchMap };

			let hasChanges = false;

			data.forEach((branch) => {
				if (!newBranchMap[branch.id]) {
					hasChanges = true;
					newBranchMap[branch.id] = branch;
				}
			});

			if (hasChanges) return newBranchMap;

			return prevBranchMap;
		});
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const loadMoreBranches = useCallback(
		debounce((map: google.maps.Map) => {
			const { ne, sw } = getMapBounds(map);
			const currentZoom = map?.getZoom();

			if (ne && sw && currentZoom && 8 <= currentZoom && currentZoom < 18) {
				const neBounds = `${ne.lat()},${ne.lng()}`;
				const swBounds = `${sw.lat()},${sw.lng()}`;

				branchesHttpClient.request({
					requestConfig: LIVE_TRACKING_API.getLocationBranchesListConfig(neBounds, swBounds),
					successCallback: ({ data }) => {
						addNewBranchesToMap(data);
					},
				});
			}
		}, SEARCH_DEBOUNCE_TIMER),
		[]
	);

	return {
		branchMap,
		loadMoreBranches,
	};
};
