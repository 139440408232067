import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { useSelector } from 'store';
import { getSelectedCountryIsoTwoCode } from 'store/selectors';
import { useAuth, useConfirmModal, useNewHttpClient } from 'hooks';
import { VERTICALS_API } from 'configs/api';
import { APP_PERMISSIONS } from 'configs/permissions';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import MoreActions from 'components/MoreActions/MoreActions';
import useVertical from 'pages/Verticals/hooks/useVertical';
import { App, MenuProps } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import { DeleteOutlined } from '@ant-design/icons';

export const VerticalMoreAction: FC = memo(() => {
	const navigate = useNavigate();
	const { hasPermission } = useAuth();
	const { openConfirmModal } = useConfirmModal();
	const { message } = App.useApp();

	const { t: tCommon } = useTranslation('common');
	const { t: tVerticals } = useTranslation('verticals');

	const { data, id } = useVertical();

	// ! http clients
	const deleteRequestHttp = useNewHttpClient();

	// ! selectors
	const countryIsoTwoCode = useSelector(getSelectedCountryIsoTwoCode);

	// ! handlers
	const handleDeleteVertical = (id: number): Promise<void> =>
		deleteRequestHttp.request({
			requestConfig: VERTICALS_API.delete(id),
			successCallback: () => {
				message.success(tVerticals('feedback.vertical.delete.success'));
				navigate(generatePath(ABSOLUTE_ROUTES.VERTICAL_LIST, { countryIsoTwoCode }));
			},
		});

	// ! memos
	const menuItems = useMemo<ItemType[]>(() => {
		if (!data) {
			return [];
		}

		const canDelete = hasPermission(APP_PERMISSIONS.application_configuration.vertical.delete);

		const items: ItemType[] = [
			// DELETE
			{
				key: 'delete_button',
				label: tCommon(`action_buttons.delete`),
				icon: <DeleteOutlined />,
				disabled: !canDelete,
				onClick: () => {
					openConfirmModal({
						title: tVerticals('delete_modal.title', {
							id: data.id,
							name: data.name,
						}),
						content: tVerticals(`delete_modal.content.${data.type}`),
						onOk: () => handleDeleteVertical(data.id),
					});
				},
			},
		];

		return items;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, data]);

	const menu: MenuProps = {
		items: menuItems,
	};

	// ! render
	return <MoreActions menu={menu} />;
});
