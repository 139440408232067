import { EBannerInteractionType, EBannerType, EMobileAppTarget } from 'types/api';
import { ISelectOption } from 'types/common';
import TranslatedLabel from 'components/TranslatedLabel';

const BANNER_TYPE_OPTIONS: ISelectOption[] = Object.values(EBannerType).map((value) => ({
	value,
	label: (
		<TranslatedLabel
			nameSpace='banners'
			i18nKey={`entity.banner_types.${value}`}
		/>
	),
}));

const PROMOTION_AND_SWIMLANE_OPTIONS: ISelectOption[] = [
	{
		value: EBannerType.SWIMLANE,
		label: (
			<TranslatedLabel
				nameSpace='banners'
				i18nKey={`entity.banner_types.${EBannerType.SWIMLANE}`}
			/>
		),
	},
	{
		value: EBannerType.PROMOTIONAL,
		label: (
			<TranslatedLabel
				nameSpace='banners'
				i18nKey={`entity.banner_types.${EBannerType.PROMOTIONAL}`}
			/>
		),
	},
];

export const SELECT_TYPE_OPTIONS_BY_BANNER_TYPE: Record<EBannerType, ISelectOption[]> = {
	[EBannerType.HIGHLIGHTED_HOME]: BANNER_TYPE_OPTIONS,
	[EBannerType.HIGHLIGHTED_ACCOUNT]: BANNER_TYPE_OPTIONS,
	[EBannerType.PROMOTIONAL]: PROMOTION_AND_SWIMLANE_OPTIONS,
	[EBannerType.SWIMLANE]: PROMOTION_AND_SWIMLANE_OPTIONS,
};

export const BANNER_INTERACTION_TYPE_OPTIONS: ISelectOption[] = Object.values(EBannerInteractionType).map((value) => ({
	value,
	label: (
		<TranslatedLabel
			nameSpace='banners'
			i18nKey={`entity.interaction_types.${value}`}
		/>
	),
}));

export const BANNER_MOBILE_TARGET_OPTIONS: ISelectOption[] = Object.values(EMobileAppTarget).map((value) => ({
	value,
	label: (
		<TranslatedLabel
			nameSpace='banners'
			i18nKey={`entity.mobile_app_targets.${value.replace(':', '_')}`}
		/>
	),
}));
