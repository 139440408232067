import { ICustomer } from './customer';
import { IDriver } from './drivers';
import { IOrder } from './orders';
import { IBranch, IVendor } from './vendors';

// Refund Order
interface IRefundOrderInfo {
	code: IOrder['info']['code'];
	status: IOrder['info']['status'];
	placed_at: IOrder['info']['placed_at'];
	new_at: string;
}

interface IRefundOrderCustomer {
	id: ICustomer['id'];
	name: string;
}

interface IRefundOrderBranch {
	id: IBranch['id'];
	name: IBranch['info']['name'];
	timezone: IBranch['location']['timezone'];
	vendor_id: IVendor['id'];
}

interface IRefundOrderPayment {
	amount_payable: number;
	type: number;
	paid_at: string;
}

interface IRefundOrderDriver {
	id?: IDriver['id'];
	name?: string;
}

export enum ERefundStatus {
	REFUNDED = 'refunded',
	CANCELED = 'canceled',
	REJECTED = 'rejected',
	PENDING = 'pending',
	ERROR = 'error',
}

export interface IRefundOrder {
	id: number;
	info: IRefundOrderInfo;
	customer: IRefundOrderCustomer;
	store: IRefundOrderBranch;
	payment: IRefundOrderPayment;
	driver?: IRefundOrderDriver;
}

// Refund Elements
export interface IRefundInfo {
	code: string;
	amount: number;
	currency_code: string;
	payment_method: string; // Set as a string because the value comes from myfatoorah
	comment: string;
	date: string;
	status: ERefundStatus;
	// refund cost source
	cost_to_vendor: number;
	cost_to_platform: number;
}

export interface IRefundAuthor {
	id: number;
	name: string;
}

interface IRefundPromotion {
	id: number;
	code: string;
	amount: number;
}

// ! Refund
export interface IRefund {
	id: number;
	info: IRefundInfo;
	author: IRefundAuthor;
	order: IRefundOrder;
	promotion?: IRefundPromotion;
}

// * CRUD
export interface ICreateRefundData {
	order_id: IOrder['id'];
	amount: number;
	payment_method_id: number;
	comment: string;
	// refund cost source
	cost_to_vendor?: number;
	cost_to_platform?: number;
	promotion_amount?: number;
}

export interface ICreateRefundForm {
	comment: string;
	amount: number;
	cost_to_platform?: number;
	cost_to_vendor?: number;
	payment_method_id: number;
	promotion_amount?: number;
}

export interface IUpdateRefundRequestBody {
	cost_to_vendor: number;
	cost_to_platform: number;
}

export interface IAutomaticRefundPayload {
	order_id: IOrder['id'];
	refund_id: IRefund['id'];
	cost_to_vendor: IRefund['info']['cost_to_vendor'];
	cost_to_platform: IRefund['info']['cost_to_platform'];
}
