import { FC, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BANNERS_API } from 'configs/api';
import { IBanner } from 'types/api';
import { EImgFileSize, ESupportedLanguages } from 'types/common';
import { IBannerUploadImgModalProps } from './types';
import ImgEditModal from 'components/ImgEditModal';
import { BANNERS_SUPPORTED_LANGUAGES } from '../BannerList/configs';
import { Flex, Radio, RadioChangeEvent, Typography as T } from 'antd';

const BannerUploadImgModal: FC<IBannerUploadImgModalProps> = ({ bannerId, language, onClose }) => {
	const { t: tBanners } = useTranslation('banners');

	// ! states
	const [uploadBannerLanguage, setUploadBannerLanguage] = useState<ESupportedLanguages>(ESupportedLanguages.ENGLISH);

	// ! effect
	useEffect(() => {
		if (!language) return;

		setUploadBannerLanguage(language);
	}, [language]);

	// ! handles
	const onCloseModal = (refresh: boolean) => {
		setUploadBannerLanguage(ESupportedLanguages.ENGLISH);
		// on close callback
		onClose(refresh);
	};

	const onLanguageChange = (ev: RadioChangeEvent) => {
		setUploadBannerLanguage(ev.target.value);
	};

	// ! render
	return (
		<ImgEditModal<IBanner>
			open={!!bannerId}
			maxImgSize={EImgFileSize.BANNER}
			uploadFileDataName='banner_image'
			extraInfo={tBanners('upload_image_modal.upload_alert')}
			title={tBanners('upload_image_modal.title', { id: bannerId })}
			httpRequestConfig={(data: FormData) => {
				data.append('lang', uploadBannerLanguage);
				return BANNERS_API.updateBannerImageConfig(bannerId!, data);
			}}
			onCloseModal={onCloseModal}
		>
			{language === undefined && (
				<Flex gap='small'>
					<T.Text>{tBanners('edit_modal.language_version')}</T.Text>

					<Radio.Group
						size='small'
						buttonStyle='solid'
						optionType='button'
						value={uploadBannerLanguage}
						options={BANNERS_SUPPORTED_LANGUAGES}
						onChange={onLanguageChange}
					/>
				</Flex>
			)}
		</ImgEditModal>
	);
};

export default memo(BannerUploadImgModal);
