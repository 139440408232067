import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CARD_IMG_BODY_STYLES } from 'configs/common';
import { IBanner } from 'types/api';
import { ESupportedLanguages, Nullable, TEmptyFunction } from 'types/common';
import ImageBox from 'components/ImageBox';
import BannerUploadImgModal from '../components/BannerUploadImgModal';
import { Card, Col, Row } from 'antd';

interface IBannerImagePreviewUploadProps {
	isLoading: boolean;
	bannerId: IBanner['id'];
	image: IBanner['image'];
	image_ar: IBanner['image_ar'];
	onCloseBannerUploadImgModalCallback: TEmptyFunction;
}

const BannerImagePreviewUpload: FC<IBannerImagePreviewUploadProps> = ({
	image,
	image_ar,
	isLoading,
	bannerId,
	onCloseBannerUploadImgModalCallback,
}) => {
	const { t: tBanners } = useTranslation('banners');

	// ! states
	const [uploadingBannerImgId, setUploadingBannerImgId] = useState<Nullable<number>>(null);
	const bannerImgLanguage = useRef<ESupportedLanguages>();

	// ! handlers
	const onOpenBannerUploadImgModal = (lang: ESupportedLanguages) => {
		if (!bannerId) return;

		bannerImgLanguage.current = lang;
		setUploadingBannerImgId(bannerId);
	};

	const onCloseBannerUploadImgModal = (refresh: boolean) => {
		bannerImgLanguage.current = undefined;
		setUploadingBannerImgId(null);

		if (refresh) onCloseBannerUploadImgModalCallback();
	};

	// ! render

	return (
		<Row
			gutter={16}
			wrap={false}
		>
			<Col flex='auto'>
				<Card
					size='small'
					title={tBanners('entity.image')}
					styles={{
						body: CARD_IMG_BODY_STYLES,
					}}
				>
					<ImageBox
						bordered={false}
						buttonDisabled={isLoading}
						image={image || ''}
						extraInfo={tBanners('upload_image_modal.upload_alert')}
						onAddEditBtnClick={() => onOpenBannerUploadImgModal(ESupportedLanguages.ENGLISH)}
					/>
				</Card>
			</Col>
			<Col flex='auto'>
				<Card
					size='small'
					title={tBanners('entity.image_ar')}
					styles={{
						body: CARD_IMG_BODY_STYLES,
					}}
				>
					<ImageBox
						bordered={false}
						buttonDisabled={isLoading}
						image={image_ar || ''}
						extraInfo={tBanners('upload_image_modal.upload_alert')}
						onAddEditBtnClick={() => onOpenBannerUploadImgModal(ESupportedLanguages.ARABIC)}
					/>
				</Card>
			</Col>

			{/* BANNER IMG EDIT MODAL */}
			<BannerUploadImgModal
				bannerId={uploadingBannerImgId}
				language={bannerImgLanguage.current}
				onClose={onCloseBannerUploadImgModal}
			/>
		</Row>
	);
};

export default BannerImagePreviewUpload;
