// Example usage:
// console.log(classNames('btn', 'btn-primary')); // "btn btn-primary"
// console.log(classNames('btn', { 'btn-active': true, 'btn-disabled': false })); // "btn btn-active"
// console.log(classNames('alert', ['alert-danger', { 'fade-out': false, show: true }])); // "alert alert-danger show"
// console.log(classNames()); // ""

export const classNames = (...args: (string | Record<string, boolean> | undefined | null | false)[]) => {
	return args
		.flat()
		.filter(
			(arg) =>
				typeof arg === 'string' ||
				(typeof arg === 'object' && arg !== null && Object.entries(arg).some(([key, value]) => value && key))
		)
		.map((arg) => {
			if (typeof arg === 'string') {
				return arg;
			} else if (typeof arg === 'object' && arg !== null) {
				return Object.keys(arg)
					.filter((key) => arg[key])
					.join(' ');
			}
			return '';
		})
		.join(' ')
		.trim();
};
