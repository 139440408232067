import {
	Dispatch,
	PropsWithChildren,
	SetStateAction,
	createContext,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useBranch, useGeneralSettings, useNewHttpClient, useVendor } from 'hooks';
import { VENDOR_PRODUCT_API, VENDOR_PRODUCT_SNAPSHOT_API } from 'configs/api';
import {
	EGeneralSettingsKeys,
	IOptionGroupsSavePayload,
	IProduct,
	IProductDetailsPayload,
	IVariantBulkSavePayload,
} from 'types/api';
import { EStatus, Nullable, TEmptyFunction } from 'types/common';
import { IHttpClientData, THttpRequestConfig } from './useHttpClient/types';
import { App } from 'antd';

interface IProductContextData {
	vendorId: number;
	branchId: number;
	menuId: number;
	onBack: TEmptyFunction;
	//
	generalSettingsLoading: boolean;
	productMaxQuantitySetting: Nullable<string>;
	//
	productId: number;
	isProductSnapshot: boolean;
	productData: Nullable<IProduct>;
	setProductData: Dispatch<SetStateAction<Nullable<IProduct>>>;
	//
	fetchProduct: () => Promise<void>;
	fetchProductHttpClient: IHttpClientData<IProduct>;
	createProduct: (data: IProductDetailsPayload, isProceedToEditProduct: boolean) => Promise<void>;
	createProductHttpClient: IHttpClientData<IProduct>;
	patchProduct: (data: Partial<IProductDetailsPayload>) => Promise<void>;
	patchProductHttpClient: IHttpClientData<IProduct>;
	toggleProductStatus: (status: EStatus) => Promise<void>;
	resetProductSnapshotPrice: () => Promise<void>;
	updateProductImgConfig: (data: FormData) => THttpRequestConfig;
	//
	bulkSaveProductVariants: (data: IVariantBulkSavePayload) => Promise<void>;
	bulkSaveProductVariantsHttpClient: IHttpClientData<IProduct>;
	//
	bulkSaveProductOptionGroups: (data: IOptionGroupsSavePayload) => Promise<void>;
	bulkSaveProductOptionGroupsHttpClient: IHttpClientData<IProduct>;
}

// ! context
const useProduct = () => {
	return useContext(ProductContext);
};

const ProductContext = createContext<IProductContextData>({} as IProductContextData);

// ! provider
const ProductProvider = ({ children = <Outlet /> }: PropsWithChildren) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { message } = App.useApp();

	const { t: tProduct } = useTranslation('product');

	const { vendorId } = useVendor();
	const { branchId } = useBranch();
	const { menuId = '', productId = '' } = useParams();

	const [params] = useSearchParams(new URLSearchParams());
	const categoryId = params.get('categoryId') || '';
	const subCategoryIdParam = params.get('subCategoryId');
	const subCategoryId = subCategoryIdParam ? +subCategoryIdParam : undefined;

	const isProductSnapshot = !!branchId && !!menuId;

	const updateProductImgConfig = (data: FormData) =>
		VENDOR_PRODUCT_API.updateProductImage(vendorId, +productId, data);

	// max quantity info
	const { isLoading: generalSettingsLoading, getSettingValue } = useGeneralSettings(
		EGeneralSettingsKeys.PRODUCT_MAX_QUANTITY
	);
	const productMaxQuantitySetting = getSettingValue(EGeneralSettingsKeys.PRODUCT_MAX_QUANTITY);

	// ! refs
	const lastRouteState = useRef(location.state?.previousLocation);

	// ! http clients
	const fetchProductHttpClient = useNewHttpClient<IProduct>();
	const createProductHttpClient = useNewHttpClient<IProduct>();
	const patchProductHttpClient = useNewHttpClient<IProduct>();
	const toggleStatusHttpClient = useNewHttpClient<IProduct>();
	const resetPriceProductSnapshotHttpClient = useNewHttpClient<IProduct>();
	const bulkSaveProductVariantsHttpClient = useNewHttpClient<IProduct>();
	const bulkSaveProductOptionGroupsHttpClient = useNewHttpClient<IProduct>();

	// ! states
	const [productData, setProductData] = useState<Nullable<IProduct>>(null);

	// ! handlers
	const onBack = () => {
		if (isProductSnapshot) {
			navigate('../..');
			return;
		}

		if (lastRouteState?.current) {
			navigate(lastRouteState?.current);
			return;
		}

		navigate('..');
	};

	const fetchProduct = () => {
		if (!vendorId || !productId) return Promise.reject();

		const requestConfig = isProductSnapshot
			? VENDOR_PRODUCT_SNAPSHOT_API.getProduct(
					vendorId,
					branchId,
					+menuId,
					+categoryId,
					+productId,
					subCategoryId
			  )
			: VENDOR_PRODUCT_API.getProduct(vendorId, +productId);

		return fetchProductHttpClient.request({
			requestConfig,
			successCallback: (data) => setProductData(data),
		});
	};

	const createProduct = (data: IProductDetailsPayload, isProceedToEditProduct: boolean) => {
		if (!vendorId) return Promise.reject();

		return createProductHttpClient.request({
			requestConfig: VENDOR_PRODUCT_API.createProduct(vendorId, data),
			successCallback: (data) => {
				setProductData(data);
				message.success(tProduct('messages.create.success'), 3);
				// in the end..
				if (isProceedToEditProduct) {
					navigate('../' + data.id, { replace: true });
				} else {
					onBack();
				}
			},
		});
	};

	const patchProduct = (data: Partial<IProductDetailsPayload>) => {
		if (!vendorId || !productId) return Promise.reject();

		const requestConfig = isProductSnapshot
			? VENDOR_PRODUCT_SNAPSHOT_API.patchProduct(
					vendorId,
					branchId,
					+menuId,
					+categoryId,
					+productId,
					data,
					subCategoryId
			  )
			: VENDOR_PRODUCT_API.patchProduct(vendorId, +productId, data);

		return patchProductHttpClient.request({
			requestConfig,
			successCallback: (data) => {
				setProductData(data);
				message.success(tProduct('messages.update.success'), 3);
			},
		});
	};

	const toggleProductStatus = (status: EStatus) => {
		if (!vendorId || !productId) return Promise.reject();

		const requestConfig = isProductSnapshot
			? VENDOR_PRODUCT_SNAPSHOT_API.patchProduct(
					vendorId,
					branchId,
					+menuId,
					+categoryId,
					+productId,
					{ status },
					subCategoryId
			  )
			: VENDOR_PRODUCT_API.patchProduct(vendorId, +productId, { status });

		return toggleStatusHttpClient.request({
			requestConfig,
			successCallback: (data) => {
				setProductData(data);
				message.success(tProduct(`messages.status.success`), 3);
			},
		});
	};

	const resetProductSnapshotPrice = () => {
		if (!isProductSnapshot || !vendorId || !productId) return Promise.reject();

		return resetPriceProductSnapshotHttpClient.request({
			requestConfig: VENDOR_PRODUCT_SNAPSHOT_API.resetPrice(
				vendorId,
				branchId,
				+menuId,
				+productId,
				+categoryId,
				subCategoryId
			),
			successCallback: (data: IProduct) => {
				setProductData(data);
				message.success(tProduct(`messages.status.success`), 3);
				fetchProduct();
			},
		});
	};

	// * variants
	const bulkSaveProductVariants = (data: IVariantBulkSavePayload) => {
		if (!vendorId || !productId) return Promise.reject();

		const requestConfig = isProductSnapshot
			? VENDOR_PRODUCT_SNAPSHOT_API.bulkSaveVariants(
					vendorId,
					branchId,
					+menuId,
					+categoryId,
					+productId,
					data,
					subCategoryId
			  )
			: VENDOR_PRODUCT_API.bulkSaveVariants(vendorId, +productId, data);

		return bulkSaveProductVariantsHttpClient.request({
			requestConfig,
			successCallback: (response) => {
				setProductData(response);
				message.success(tProduct('messages.variant.bulk.success'), 3);
			},
		});
	};

	// * option groups
	const bulkSaveProductOptionGroups = (data: IOptionGroupsSavePayload) => {
		if (!vendorId || !productId) return Promise.reject();

		const requestConfig = isProductSnapshot
			? VENDOR_PRODUCT_SNAPSHOT_API.bulkSaveOptionGroups(
					vendorId,
					branchId,
					+menuId,
					+categoryId,
					+productId,
					data,
					subCategoryId
			  )
			: VENDOR_PRODUCT_API.bulkSaveOptionGroups(vendorId, +productId, data);

		return bulkSaveProductOptionGroupsHttpClient.request({
			requestConfig,
			successCallback: (response) => {
				setProductData(response);
				message.success(tProduct('messages.option_group.bulk.success'), 3);
			},
		});
	};

	// ! effects
	// * fetch data
	useEffect(() => {
		if (+productId > 0 && !productData) {
			fetchProduct();

			// unmount
			return () => {
				setProductData(null);
				lastRouteState.current = undefined;
			};
		}
	}, [productId]); // eslint-disable-line react-hooks/exhaustive-deps

	// ! return
	if (!vendorId) return null;

	const vendorData: IProductContextData = {
		vendorId,
		branchId,
		menuId: +menuId,
		onBack,
		//
		generalSettingsLoading,
		productMaxQuantitySetting,
		//
		productId: +productId,
		isProductSnapshot,
		productData,
		setProductData,
		//
		fetchProduct,
		fetchProductHttpClient,
		createProduct,
		createProductHttpClient,
		patchProduct,
		patchProductHttpClient,
		toggleProductStatus,
		updateProductImgConfig,
		resetProductSnapshotPrice,
		//
		bulkSaveProductVariants,
		bulkSaveProductVariantsHttpClient,
		//
		bulkSaveProductOptionGroups,
		bulkSaveProductOptionGroupsHttpClient,
	};

	return <ProductContext.Provider value={vendorData}>{children}</ProductContext.Provider>;
};

export { useProduct, ProductProvider };
export default useProduct;
