import { useMemo } from 'react';
import { useMatches } from 'react-router-dom';
import { convertIRouteToMenuItemType } from './helper';
import { INavMenuRoute, IRouteObjectHandle } from 'configs/routes';

export const useNavMenuController = (items: INavMenuRoute[]) => {
	const matches = useMatches();

	const navItems = items.map(convertIRouteToMenuItemType);

	const selectedNavItems = useMemo(() => {
		return matches
			.map((route) => (route.handle as IRouteObjectHandle['handle'])?.translationKey ?? '')
			.filter((elem) => elem);
	}, [matches]);

	return { selectedNavItems, navItems };
};
