import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { VendorProvider } from 'hooks/useVendor';
import { LIST_DEFAULT_PARAMS } from 'configs/api';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import { IntegrationFetchWrapper } from 'appWrappers/IntegrationFetchWrapper';
import ListParamsRouteWrapper from 'components/ListParamsRouteWrapper';
import { EVendorTabsKeys } from 'pages/Vendors/VendorDetails/Tabs/types';
import { VENDOR_TAB_ROUTES } from './routes.vendors-tabs';
import { ApartmentOutlined, UnorderedListOutlined } from '@ant-design/icons';

// ! Lazy module imports
const VendorList = lazy(() => import('pages/Vendors'));
const AddEditVendor = lazy(() => import('pages/Vendors/AddEditVendor'));
const VendorDetailsLayout = lazy(() => import('../VendorDetails'));

export const VENDOR_ROUTES: TRouteObject = {
	path: 'vendors',
	element: (
		<ListParamsRouteWrapper key='vendors'>
			<IntegrationFetchWrapper />
		</ListParamsRouteWrapper>
	),
	handle: {
		translationKey: 'vendors',
		permissions: [APP_PERMISSIONS.vendor.view],
		icon: <ApartmentOutlined />,
		defaultListParams: LIST_DEFAULT_PARAMS,
		showOnNav: true,
	},
	children: [
		{
			index: true,
			element: <VendorList />,
			handle: {
				translationKey: 'vendors_list',
				permissions: [APP_PERMISSIONS.vendor.view],
				icon: <UnorderedListOutlined />,
				defaultListParams: LIST_DEFAULT_PARAMS,
				showOnNav: false,
			},
		},
		{
			path: 'new',
			element: <AddEditVendor />,
			handle: {
				translationKey: 'add_new_vendor',
				permissions: [APP_PERMISSIONS.vendor.add, APP_PERMISSIONS.application_configuration.vertical.view],
				showOnNav: false,
			},
		},
		{
			path: ':vendorId',
			handle: {
				translationKey: 'vendor_details',
				permissions: [APP_PERMISSIONS.vendor.view],
				showOnNav: false,
			},
			element: (
				<VendorProvider>
					<VendorDetailsLayout />
				</VendorProvider>
			),
			children: [
				{
					index: true,
					element: (
						<Navigate
							to={EVendorTabsKeys.DETAILS}
							replace
						/>
					),
					handle: {
						translationKey: 'vendor_details',
						permissions: [APP_PERMISSIONS.vendor.view],
						showOnNav: false,
					},
				},
				// Edit Vendor Route
				{
					path: 'edit',
					element: <AddEditVendor />,
					handle: {
						translationKey: 'edit_vendor_details',
						permissions: [APP_PERMISSIONS.vendor.update],
						showOnNav: false,
					},
				},
				// Other tabs
				...VENDOR_TAB_ROUTES,
			],
		},
	],
};
