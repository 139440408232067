import { ReactNode } from 'react';
import { EBannerType } from 'types/api';
import { EBannerListViewMode, EBannerTypeTabs, TBannersPageType } from './types';
import TranslatedLabel from 'components/TranslatedLabel';
import { EAppConfigurationPage } from 'pages/HomeAppConfiguration/types';
import { FlagOutlined, HighlightOutlined, OrderedListOutlined, TableOutlined } from '@ant-design/icons';

export const BANNERS_TAB_ICONS: Record<EBannerTypeTabs, ReactNode> = {
	[EBannerTypeTabs.HIGHLIGHTED_HOME]: <HighlightOutlined />,
	[EBannerTypeTabs.HIGHLIGHTED_ACCOUNT]: <HighlightOutlined />,
	[EBannerTypeTabs.PROMOTIONAL_SWIMLANE]: <FlagOutlined />,
};

export const BANNERS_VIEW_MODE_OPTIONS = [
	{ icon: <TableOutlined />, value: EBannerListViewMode.TABLE },
	{ icon: <OrderedListOutlined />, value: EBannerListViewMode.PREVIEW },
];

export const BANNER_LIST_TABS_ROUTES = Object.values(EBannerTypeTabs).map((type) => ({
	path: type,
	handle: {
		icon: BANNERS_TAB_ICONS[type],
		label: (
			<TranslatedLabel
				nameSpace='banners'
				i18nKey={type}
				extra={{ keyPrefix: 'entity.banner_types' }}
			/>
		),
	},
}));

export const BANNER_TYPE_TAB_BY_APP_PAGE: Record<TBannersPageType, EBannerTypeTabs[]> = {
	[EAppConfigurationPage.HUB]: [EBannerTypeTabs.HIGHLIGHTED_HOME, EBannerTypeTabs.PROMOTIONAL_SWIMLANE],
	[EAppConfigurationPage.ACCOUNT]: [EBannerTypeTabs.HIGHLIGHTED_ACCOUNT],
};

export const BANNER_TAB_BY_BANNER_TYPE: Record<EBannerType, EBannerTypeTabs> = {
	[EBannerType.HIGHLIGHTED_HOME]: EBannerTypeTabs.HIGHLIGHTED_HOME,
	[EBannerType.HIGHLIGHTED_ACCOUNT]: EBannerTypeTabs.HIGHLIGHTED_ACCOUNT,
	[EBannerType.PROMOTIONAL]: EBannerTypeTabs.PROMOTIONAL_SWIMLANE,
	[EBannerType.SWIMLANE]: EBannerTypeTabs.PROMOTIONAL_SWIMLANE,
};

export const DEFAULT_BANNER_TYPE_BY_BANNER_TAB: Record<EBannerTypeTabs, EBannerType> = {
	[EBannerTypeTabs.HIGHLIGHTED_HOME]: EBannerType.HIGHLIGHTED_HOME,
	[EBannerTypeTabs.HIGHLIGHTED_ACCOUNT]: EBannerType.HIGHLIGHTED_ACCOUNT,
	[EBannerTypeTabs.PROMOTIONAL_SWIMLANE]: EBannerType.PROMOTIONAL,
};
