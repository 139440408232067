import { ECustomerStatus } from 'types/api';
import { ISelectOption } from 'types/common';
import TranslatedLabel from 'components/TranslatedLabel';

// ! status options
export const CUSTOMER_STATUS_SELECT_OPTIONS: ISelectOption[] = Object.values(ECustomerStatus)
	.filter((status) => status !== ECustomerStatus.DELETED)
	.map((status) => ({
		value: status,
		label: (
			<TranslatedLabel
				nameSpace='customers'
				i18nKey={`shared.status_options.${status}`}
			/>
		),
	}));
