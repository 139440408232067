import { forwardRef } from 'react';
import { NavLink } from 'react-router-dom';
import { ILinkWrapperProps } from '../types';

const LinkWrapper = forwardRef<HTMLAnchorElement, ILinkWrapperProps>(({ children, to, ...props }, ref) => {
	return (
		<NavLink
			to={to}
			{...props}
			ref={ref}
		>
			{children}
		</NavLink>
	);
});

export default LinkWrapper;
