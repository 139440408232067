import { EStatus } from 'types/common';
import { IVendor, IVendorImages, IVendorInfo } from './vendors';
import { IVertical } from './verticals';

// ! enums & types
export enum EBannerType {
	HIGHLIGHTED_HOME = 'highlighted_home',
	HIGHLIGHTED_ACCOUNT = 'highlighted_account',
	PROMOTIONAL = 'promotional', //matches MobileAppHomeScreenStoresListEntryType.BANNER
	SWIMLANE = 'swimlane', //matches MobileAppHomeScreenStoresListEntryType.HORIZONTAL_STORE_LIST
}

export enum EBannerInteractionType {
	EXTERNAL_LINK = 'external_link',
	INFO = 'info',
	INTERNAL_LINK = 'internal_link',
	STORE = 'store',
	VENDOR = 'vendor',
	VERTICAL = 'vertical',
}

export enum EMobileAppTarget {
	SCREEN_CARI_UNLIMITED = 'screen:cari_unlimited',
	SCREEN_CARI_PRIZE = 'screen:cari_prize',
	SCREEN_WALLET = 'screen:wallet',
	SCREEN_CART = 'screen:cart',
	SCREEN_ACCOUNT = 'screen:account',
}

// ! interfaces
export interface IBanner {
	id: number;
	name: string;
	name_ar: string;

	title?: string; // IF type SWIMLANE
	title_ar?: string; // IF type SWIMLANE
	swimlane_vendors?: IVendorSwimlaneDetails[]; // IF type SWIMLANE

	image: string;
	image_ar: string;

	status: EStatus;
	type: EBannerType;
	position: number;

	interaction_type: EBannerInteractionType;
	redirection_link?: string; // if EXTERNAL_LINK
	mobile_app_target?: EMobileAppTarget; // if INTERNAL_LINK
	store_id?: number; // if STORE Link
	vendor_id?: number; // if VENDOR Link
	vertical_id?: IVertical['id']; // if VERTICAL Link

	// on GET details
	store_name?: string;
	vendor_name?: string;
	vertical_name?: IVertical['name'];
}

// ! CRUD
export interface ICreateUpdateBannerPayload
	extends Omit<IBanner, 'id' | 'image' | 'image_ar' | 'status' | 'store_name' | 'vendor_name'> {}

// SWIMLANES
export interface IVendorSwimlaneDetails {
	vendor_id: IVendor['id'];
	position: number;
	name: IVendorInfo['name'];
	name_ar: IVendorInfo['name_ar'];
	image: IVendorImages['image'];
}

export interface IVendorsSwimlane {
	position: number;
	vendor_id: IVendor['id'];
}

export interface ISwimlanePayload {
	id?: ISwimlane['id'];
	swimlane_vendors?: IVendorsSwimlane[];
}

export interface ISwimlane {
	id: IBanner['id'];
	status: EStatus;
	title: IBanner['title'];
	title_ar: IBanner['title_ar'];
	position: IBanner['position'];
	swimlane_vendors?: IVendorSwimlaneDetails[];
}
