import { lazy } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { VISUAL_DIVIDER_ROUTE } from 'configs/DividerRoute';
import { APP_PERMISSIONS, SUPER_ADMIN_PERMISSION } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import { EAppConfigurationPage } from './types';
import ListParamsRouteWrapper from 'components/ListParamsRouteWrapper';
import PageContainer from 'components/PageContainer';
import { BannerListPageLayout } from 'pages/Banners';
import { AddEditBannerProvider } from 'pages/Banners/AddEditBannerProvider';
import { BANNER_TYPE_TAB_BY_APP_PAGE } from 'pages/Banners/configs';
import { BANNER_DETAILS_ROUTE } from 'pages/Banners/routes.banners';
import { AllVerticalServicesPage } from 'pages/HomeAppConfiguration/AllVerticalServicesPage/AllVerticalServicesPage';
import { VERTICAL_ROUTES } from 'pages/Verticals/routes/routes.verticals';
import { Table } from 'antd';
import {
	ClockCircleOutlined,
	FileSyncOutlined,
	FlagOutlined,
	LinkOutlined,
	RadiusSettingOutlined,
} from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';

// ! lazy imports
const HomeAppConfiguration = lazy(() => import('./HomeAppConfiguration'));
const EditHomeComponent = lazy(() => import('./EditHomeComponent'));
const DeepLinksPage = lazy(() => import('./DeepLinksPage'));

const TBIPage = ({ children = <Outlet />, ...props }) => {
	return (
		<PageContainer
			pageHeader={
				<PageHeader
					title={props.title || 'TBI PAGE'}
					subTitle='TBI'
				/>
			}
		>
			{children}
		</PageContainer>
	);
};

export const APPLICATION_CONFIGURATION_ROUTES: TRouteObject = {
	path: 'application-configuration',
	handle: {
		showOnNav: true,
		isNavigateable: false,
		icon: <FileSyncOutlined />,
		translationKey: 'application_configuration',
		permissions: [],
	},
	children: [
		// ! Home
		{
			path: EAppConfigurationPage.HOME,
			handle: {
				showOnNav: true,
				translationKey: 'new_home_screen_management',
				icon: <RadiusSettingOutlined />,
				mustHaveABs: { CORE_6756_NEW_HOME_SCREEN: 'ACTIVE' },
				permissions: [SUPER_ADMIN_PERMISSION],
			},
			element: <HomeAppConfiguration />,
		},
		// Edit
		{
			path: 'edit/:id',
			element: <EditHomeComponent />,
			handle: {
				permissions: [SUPER_ADMIN_PERMISSION],
			},
		},

		// ! All Services
		{
			path: EAppConfigurationPage.ALL_SERVICES,
			handle: {
				showOnNav: true,
				translationKey: 'all_services_screen',
				icon: <ClockCircleOutlined />,
				mustHaveABs: { CORE_6756_NEW_HOME_SCREEN: 'ACTIVE' },
				permissions: [SUPER_ADMIN_PERMISSION],
			},
			element: <AllVerticalServicesPage />,
		},

		{
			handle: {
				...VISUAL_DIVIDER_ROUTE.handle,
				permissions: [SUPER_ADMIN_PERMISSION],
			},
		},

		// ! Hub Layout
		{
			path: EAppConfigurationPage.HUB,
			element: (
				<AddEditBannerProvider>
					<ListParamsRouteWrapper key='hub_banners' />
				</AddEditBannerProvider>
			),
			handle: {
				showOnNav: true,
				translationKey: 'temp_hub_screen', // !! TEMP !! --> change to 'hub_screen' when new home page is available
				permissions: [APP_PERMISSIONS.application_configuration.banners.view],
			},
			children: [
				{
					index: true,
					element: <Navigate to={BANNER_TYPE_TAB_BY_APP_PAGE[EAppConfigurationPage.HUB][0]} />,
				},
				{
					path: ':bannerType',
					children: [
						{
							index: true,
							element: <BannerListPageLayout pageType={EAppConfigurationPage.HUB} />,
						},
						BANNER_DETAILS_ROUTE,
					],
				},
			],
		},

		// ! Account Layout
		{
			path: EAppConfigurationPage.ACCOUNT,
			element: (
				<AddEditBannerProvider>
					<ListParamsRouteWrapper key='account_banners' />
				</AddEditBannerProvider>
			),
			handle: {
				showOnNav: true,
				translationKey: 'account_screen',
				permissions: [APP_PERMISSIONS.application_configuration.banners.view],
			},
			children: [
				{
					index: true,
					element: <Navigate to={BANNER_TYPE_TAB_BY_APP_PAGE[EAppConfigurationPage.ACCOUNT][0]} />,
				},
				{
					path: ':bannerType',
					children: [
						{
							index: true,
							element: <BannerListPageLayout pageType={EAppConfigurationPage.ACCOUNT} />,
						},
						BANNER_DETAILS_ROUTE,
					],
				},
			],
		},

		{
			handle: {
				...VISUAL_DIVIDER_ROUTE.handle,
				permissions: [APP_PERMISSIONS.application_configuration.banners.view],
			},
		},

		// ! Verticals
		VERTICAL_ROUTES,

		// ! Banners
		{
			path: EAppConfigurationPage.BANNERS,
			handle: {
				showOnNav: true,
				translationKey: 'banners',
				icon: <FlagOutlined />,
				mustHaveABs: { CORE_6756_NEW_HOME_SCREEN: 'ACTIVE' },
				permissions: [SUPER_ADMIN_PERMISSION],
			},
			element: (
				<TBIPage title='Banners'>
					<Table />
				</TBIPage>
			),
		},

		// ! Deep links
		{
			path: EAppConfigurationPage.DEEP_LINKS,
			handle: {
				showOnNav: true,
				translationKey: 'deep_links',
				icon: <LinkOutlined />,
				permissions: [APP_PERMISSIONS.application_configuration.deep_link.generate],
			},
			element: <DeepLinksPage />,
		},
	],
};
