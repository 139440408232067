import { useTranslation } from 'react-i18next';
import PageContainer from 'components/PageContainer';
import { Button } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

export const AllVerticalServicesPage = () => {
	const { t: tCommon } = useTranslation();
	const { t: tMissingTranslations } = useTranslation('missing', { keyPrefix: 'missing_translations' });

	// ! handlers
	const handleOnCreateGroup = () => {
		throw new Error('TBI');
	};

	// ! render
	return (
		<PageContainer
			pageHeader={
				<PageHeader
					title={tCommon('nav_items.all_services_screen')}
					extra={
						<Button
							type='primary'
							onClick={handleOnCreateGroup}
						>
							{tMissingTranslations('add.service_group')}
						</Button>
					}
				/>
			}
		></PageContainer>
	);
};
