import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { FeatureVariantWrapper } from 'hooks/FeatureVariantControl/FeatureVariantWrapper';
import { LIST_DEFAULT_PARAMS } from 'configs/api';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import ListParamsRouteWrapper from 'components/ListParamsRouteWrapper';
import { EAppConfigurationPage } from 'pages/HomeAppConfiguration/types';
import { VerticalProvider } from 'pages/Verticals/hooks/useVertical';
import { VerticalDetailsPageLayout } from '../VerticalDetails/VerticalDetailsPageLayout';
import { EVerticalTabs } from '../VerticalDetails/types';
import { VERTICAL_TABS_ROUTES } from './routes.vertical-tabs';
import { BuildOutlined } from '@ant-design/icons';

// ! lazy imports
const VerticalList = lazy(() => import('../VerticalsList'));
const AddVerticalPage = lazy(() => import('../AddVerticalPage'));

export const VERTICAL_ROUTES: TRouteObject = {
	path: EAppConfigurationPage.VERTICALS,
	element: (
		<FeatureVariantWrapper>
			<ListParamsRouteWrapper key='verticals' />
		</FeatureVariantWrapper>
	),
	handle: {
		translationKey: 'verticals',
		permissions: [APP_PERMISSIONS.application_configuration.vertical.view],
		showOnNav: true,
		icon: <BuildOutlined />,
		defaultListParams: LIST_DEFAULT_PARAMS,
	},
	children: [
		{
			index: true,
			element: <VerticalList />,
			handle: {
				translationKey: 'verticals',
				permissions: [APP_PERMISSIONS.application_configuration.vertical.view],
				icon: <BuildOutlined />,
				defaultListParams: LIST_DEFAULT_PARAMS,
				showOnNav: false,
			},
		},
		{
			path: 'new',
			element: <AddVerticalPage isAddChild={false} />,
			handle: {
				translationKey: 'add_new_vertical',
				permissions: [APP_PERMISSIONS.application_configuration.vertical.add],
				showOnNav: false,
			},
		},
		{
			path: ':verticalId',
			element: (
				<VerticalProvider>
					<VerticalDetailsPageLayout />
				</VerticalProvider>
			),
			handle: {
				translationKey: 'vertical_details',
				permissions: [],
				showOnNav: false,
			},
			children: [
				{
					index: true,
					element: (
						<Navigate
							to={EVerticalTabs.DETAILS}
							replace
						/>
					),
				},
				...VERTICAL_TABS_ROUTES,
			],
		},
	],
};
