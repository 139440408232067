import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, To, generatePath, useNavigate } from 'react-router-dom';
import { useSelector } from 'store';
import { getSelectedCountryIsoTwoCode } from 'store/selectors';
import { useAuth, useBanner } from 'hooks';
import { DEFAULT_DESCRIPTIONS_PROPS, DEFAULT_EMPTY_VALUE_PLACEHOLDER } from 'configs/common';
import { APP_PERMISSIONS } from 'configs/permissions';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import { BANNER_TAB_BY_BANNER_TYPE } from '../configs';
import { EBannerInteractionType, EBannerType, IBanner } from 'types/api';
import { EStatus } from 'types/common';
import { CopyToClipBoard } from 'components/CopyToClipBoard';
import PageContainer from 'components/PageContainer';
import Spinner from 'components/Spinner';
import StatusSwitch from 'components/StatusSwitch';
import { useAddEditBannerContext } from 'pages/Banners/AddEditBannerProvider';
import useUpdateBannerStatus from 'pages/Banners/hooks/useUpdateBannerStatus';
import ErrorPage from 'pages/ErrorPage';
import { EVendorTabsKeys } from 'pages/Vendors/VendorDetails/Tabs/types';
import SwimlaneDetailedCard from '../components/SwimlaneDetailedCard';
import styles from './BannerDetailsPage.module.css';
import BannerImagePreviewUpload from './BannerImagePreviewUpload';
import { BannerMoreActions } from './BannerMoreAction';
import { Button, Card, Col, Descriptions, Flex, Row } from 'antd';
import { ApartmentOutlined, EditOutlined, ShopOutlined } from '@ant-design/icons';
import { BuildOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';

const StoreLabel = ({ banner }: { banner: IBanner }) => (
	<Flex gap={4}>
		<ShopOutlined />
		{`#${banner.store_id} ${banner.store_name}`}
	</Flex>
);

const VendorLabel = ({ banner }: { banner: IBanner }) => (
	<Flex gap={4}>
		<ApartmentOutlined />
		{`#${banner.vendor_id} ${banner.vendor_name}`}
	</Flex>
);

const VerticalLabel = ({ banner }: { banner: IBanner }) => (
	<Flex gap={4}>
		<BuildOutlined />
		{`#${banner.vertical_id} ${banner.vertical_name}`}
	</Flex>
);

const BannerDetailsPage: FC = () => {
	const navigate = useNavigate();
	const { hasPermission } = useAuth();

	const { t: tCommon } = useTranslation('common');
	const { t: tBanners } = useTranslation('banners');

	const { bannerId, data: banner, fetchData, fetchHttpClient } = useBanner();

	const editBannerModalContext = useAddEditBannerContext();
	const { onActivateBanner, onDeactivateBanner } = useUpdateBannerStatus(fetchData);

	// ! selectors
	const countryIsoTwoCode = useSelector(getSelectedCountryIsoTwoCode);

	// ! permissions
	const canEdit = hasPermission(APP_PERMISSIONS.application_configuration.banners.update);
	const canActivate = hasPermission(APP_PERMISSIONS.application_configuration.banners.activate);
	const canDeactivate = hasPermission(APP_PERMISSIONS.application_configuration.banners.deactivate);
	const canGetVendor = !!(hasPermission(APP_PERMISSIONS.vendor.view) && banner?.vendor_id);
	const canGetStore = !!(hasPermission(APP_PERMISSIONS.vendor.store.view) && banner?.vendor_id && banner?.store_id);
	const canGetVertical = hasPermission(APP_PERMISSIONS.application_configuration.vertical.view);

	// ! memos
	const pathToVendor = useMemo<To>(() => {
		if (!canGetVendor) return '';

		return generatePath(ABSOLUTE_ROUTES.VENDOR_DETAILS, {
			countryIsoTwoCode: countryIsoTwoCode,
			vendorId: banner?.vendor_id,
		});
	}, [countryIsoTwoCode, banner, canGetVendor]);

	const pathToStore = useMemo<To>(() => {
		if (!canGetStore) return '';

		return generatePath(ABSOLUTE_ROUTES.VENDOR_DETAILS_BRANCH_DETAILS, {
			countryIsoTwoCode: countryIsoTwoCode,
			vendorId: banner?.vendor_id,
			vendorTab: EVendorTabsKeys.BRANCHES,
			branchId: banner?.store_id,
		});
	}, [countryIsoTwoCode, banner, canGetStore]);

	const isSwimlane = useMemo(() => banner?.type === EBannerType.SWIMLANE, [banner?.type]);

	const pathToVertical = useMemo<To>(() => {
		if (!banner?.vertical_id) return '';

		return generatePath(ABSOLUTE_ROUTES.VERTICAL_DETAILS, {
			countryIsoTwoCode: countryIsoTwoCode,
			verticalId: banner?.vertical_id,
		});
	}, [countryIsoTwoCode, banner]);

	// ! handlers
	const onGoBack = () => {
		navigate(-1);
	};

	const onEdit = () => {
		if (!banner) return;

		editBannerModalContext.openModal(fetchData, BANNER_TAB_BY_BANNER_TYPE[banner.type], banner);
	};

	const onStatusChange = (newChecked: EStatus) => {
		if (!banner) return;

		if (newChecked === EStatus.ACTIVE) {
			onActivateBanner(banner.id);
		} else {
			onDeactivateBanner(banner.id);
		}
	};

	// ! render
	if (fetchHttpClient.error) {
		return (
			<ErrorPage
				status={fetchHttpClient.error.status}
				title={fetchHttpClient.error.status}
				subTitle={fetchHttpClient.error.data?.message}
			/>
		);
	}

	if (!banner || fetchHttpClient.isLoading) {
		return <Spinner defaultAntdSpinner />;
	}

	return (
		<PageContainer
			pageHeader={
				<PageHeader
					title={tBanners(`details_pages.title.${banner.type}`)}
					subTitle={`#${bannerId}`}
					extra={[
						<StatusSwitch
							key='status-switch'
							size='default'
							status={banner?.status}
							disabled={!canActivate && !canDeactivate}
							onChange={onStatusChange}
						/>,
						<BannerMoreActions key='more-actions' />,
					]}
					onBack={onGoBack}
				/>
			}
		>
			<Flex
				vertical
				gap='large'
				className={styles.container}
			>
				<Card>
					<Row
						gutter={16}
						wrap={false}
					>
						<Col flex='auto'>
							<Descriptions {...DEFAULT_DESCRIPTIONS_PROPS}>
								<Descriptions.Item label={tBanners('entity.name')}>
									{banner?.name || DEFAULT_EMPTY_VALUE_PLACEHOLDER}
								</Descriptions.Item>
								<Descriptions.Item label={tBanners('entity.name_ar')}>
									{banner?.name_ar || DEFAULT_EMPTY_VALUE_PLACEHOLDER}
								</Descriptions.Item>

								{isSwimlane && (
									<>
										<Descriptions.Item label={tBanners('entity.title')}>
											{banner?.title}
										</Descriptions.Item>
										<Descriptions.Item label={tBanners('entity.title_ar')}>
											{banner?.title_ar}
										</Descriptions.Item>
									</>
								)}

								<Descriptions.Item label={tBanners('entity.position')}>
									{banner?.position}
								</Descriptions.Item>

								<Descriptions.Item label={tBanners('entity.interaction_type')}>
									{tBanners(`entity.interaction_types.${banner?.interaction_type}`)}
								</Descriptions.Item>

								{banner?.interaction_type === EBannerInteractionType.EXTERNAL_LINK && (
									<Descriptions.Item label={tBanners('entity.redirection_link')}>
										<CopyToClipBoard>{banner?.redirection_link}</CopyToClipBoard>
									</Descriptions.Item>
								)}

								{banner?.interaction_type === EBannerInteractionType.INTERNAL_LINK && (
									<Descriptions.Item label={tBanners('entity.mobile_app_target')}>
										{tBanners(
											`entity.mobile_app_targets.${banner?.mobile_app_target?.replace(':', '_')}`
										)}
									</Descriptions.Item>
								)}

								{banner?.interaction_type === EBannerInteractionType.VENDOR && (
									<Descriptions.Item label={tBanners('entity.vendor')}>
										{canGetVendor ? (
											<NavLink to={pathToVendor}>
												<VendorLabel banner={banner} />
											</NavLink>
										) : (
											<VendorLabel banner={banner} />
										)}
									</Descriptions.Item>
								)}

								{banner?.interaction_type === EBannerInteractionType.STORE && (
									<Descriptions.Item label={tBanners('entity.store')}>
										{canGetStore ? (
											<NavLink to={pathToStore}>
												<StoreLabel banner={banner} />
											</NavLink>
										) : (
											<StoreLabel banner={banner} />
										)}
									</Descriptions.Item>
								)}

								{banner?.interaction_type === EBannerInteractionType.VERTICAL && (
									<Descriptions.Item label={tBanners('entity.vertical')}>
										{canGetVertical ? (
											<NavLink to={pathToVertical}>
												<VerticalLabel banner={banner} />
											</NavLink>
										) : (
											<VerticalLabel banner={banner} />
										)}
									</Descriptions.Item>
								)}
							</Descriptions>
						</Col>

						{/* EDIT BUTTON */}
						<Col flex='none'>
							<Button
								type='default'
								disabled={!canEdit}
								icon={<EditOutlined />}
								onClick={onEdit}
							>
								{tCommon('action_buttons.edit')}
							</Button>
						</Col>
					</Row>
				</Card>

				{isSwimlane && (
					<SwimlaneDetailedCard
						cardTitle={tBanners('entity.vendors')}
						swimlane_vendors={banner.swimlane_vendors ? banner.swimlane_vendors : []}
						swimlaneId={banner.id}
						onSwimlaneVendorsSave={fetchData}
					/>
				)}

				{!isSwimlane && (
					<BannerImagePreviewUpload
						isLoading={fetchHttpClient.isLoading}
						bannerId={banner.id}
						image={banner?.image}
						image_ar={banner.image_ar}
						onCloseBannerUploadImgModalCallback={fetchData}
					/>
				)}
			</Flex>
		</PageContainer>
	);
};

export default BannerDetailsPage;
