import { PropsWithChildren, createContext, useContext, useRef } from 'react';
import { IUseForceRefreshController, useForceRefreshData } from 'hooks';
import AddEditBannerModal from './components/AddEditBannerModal';
import { IAddEditBannerModalHandle } from './components/AddEditBannerModal/types';

interface IAddEditBannerContextData extends IUseForceRefreshController {
	openModal: IAddEditBannerModalHandle['open'];
}

// ! CONTEXT ----
const AddEditBannerContext = createContext<IAddEditBannerContextData>({} as IAddEditBannerContextData);

export const useAddEditBannerContext = () => useContext(AddEditBannerContext);

// ! PROVIDER ----
export const AddEditBannerProvider = ({ children }: PropsWithChildren) => {
	const { forceRefreshData, refreshingData } = useForceRefreshData();

	// ! refs
	const addEditBannerModalRef = useRef<IAddEditBannerModalHandle>(null);

	// ! render
	const data: IAddEditBannerContextData = {
		openModal: (...args) => addEditBannerModalRef.current?.open(...args),
		forceRefreshData,
		refreshingData,
	};

	return (
		<AddEditBannerContext.Provider value={data}>
			{children}

			<AddEditBannerModal ref={addEditBannerModalRef} />
		</AddEditBannerContext.Provider>
	);
};
