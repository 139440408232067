import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, createPath, createSearchParams, generatePath } from 'react-router-dom';
import { getSelectedCountryIsoTwoCode } from 'store/selectors';
import { useAuth } from 'hooks';
import { APP_PERMISSIONS } from 'configs/permissions';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import { Button, Tooltip } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';

export const LIVE_TRACKING_BRANCH_ID_SEARCH_PARAM = 'branchId';
export const LIVE_TRACKING_DRIVER_ID_SEARCH_PARAM = 'driverId';

interface IViewInLiveTrackingButton {
	lat: string;
	lng: string;
	type: 'branchId' | 'driverId';
	id: number;
}

export const ViewInLiveTrackingButton: FC<IViewInLiveTrackingButton> = ({ lat, lng, id, type }) => {
	const { t: tLiveTracking } = useTranslation('live-tracking');
	const { hasPermission } = useAuth();

	// ! selectors
	const countryIsoTwoCode = useSelector(getSelectedCountryIsoTwoCode);

	// ! render

	return (
		<>
			{hasPermission([APP_PERMISSIONS.live_tracking.view]) && (
				<NavLink
					to={createPath({
						pathname: generatePath(ABSOLUTE_ROUTES.LIVE_TRACKING, {
							countryIsoTwoCode,
						}),
						search: createSearchParams({
							lat,
							lng,
							zoom: '17',
							[type]: id.toString(),
						}).toString(),
					})}
				>
					<Tooltip title={tLiveTracking('view_in_live_tracking')}>
						<Button
							icon={<PlayCircleOutlined />}
							size='small'
						/>
					</Tooltip>
				</NavLink>
			)}
		</>
	);
};
