import { DEFAULT_EMPTY_VALUE_PLACEHOLDER, ID_COLUMN_WIDTH, ImageCell } from 'configs/common';
import { EBannerInteractionType, EBannerType, IVendorSwimlaneDetails } from 'types/api';
import { ESupportedLanguages, IFilterItem } from 'types/common';
import { IBannersTableData } from './types';
import DisplayNameAndNameAr from 'components/DisplayNameAndNameAr';
import TranslatedLabel from 'components/TranslatedLabel';
import { EBannerTypeTabs } from 'pages/Banners/types';
import { Flex, Typography as T, Tooltip } from 'antd';
import { ColumnType, ColumnsType } from 'antd/lib/table';
import { ApartmentOutlined } from '@ant-design/icons';

export const BANNERS_SUPPORTED_LANGUAGES = Object.values(ESupportedLanguages).map((countryIsoTwoCode) => ({
	value: countryIsoTwoCode,
	key: countryIsoTwoCode,
	label: (
		<TranslatedLabel
			nameSpace='common'
			i18nKey={`languages.${countryIsoTwoCode}`}
		/>
	),
}));

const BANNER_INTERACTION_TYPE_FILTERS_CONFIG: IFilterItem[] = Object.values(EBannerInteractionType).map((value) => ({
	value,
	text: (
		<TranslatedLabel
			nameSpace='banners'
			i18nKey={value}
			extra={{ keyPrefix: 'entity.interaction_types' }}
		/>
	),
}));

const BANNER_TYPE_FILTERS_CONFIG: IFilterItem[] = [
	{
		value: EBannerType.SWIMLANE,
		text: (
			<TranslatedLabel
				nameSpace='banners'
				i18nKey={`entity.banner_types.${EBannerType.SWIMLANE}`}
			/>
		),
	},
	{
		value: EBannerType.PROMOTIONAL,
		text: (
			<TranslatedLabel
				nameSpace='banners'
				i18nKey={`entity.banner_types.${EBannerType.PROMOTIONAL}`}
			/>
		),
	},
];

export const BANNERS_COLUMNS: Record<string, ColumnType<IBannersTableData>> = {
	id: {
		key: 'id',
		title: (
			<TranslatedLabel
				nameSpace='banners'
				i18nKey='entity.id'
			/>
		),
		fixed: 'left',
		dataIndex: 'id',
		width: ID_COLUMN_WIDTH,
	},
	image: {
		key: 'image',
		title: (
			<TranslatedLabel
				nameSpace='banners'
				i18nKey='entity.image'
			/>
		),
		width: 82,
		render: (_, { image, type }) =>
			type !== EBannerType.SWIMLANE ? <ImageCell imageSrc={image} /> : DEFAULT_EMPTY_VALUE_PLACEHOLDER,
	},
	image_ar: {
		key: 'image_ar',
		title: (
			<TranslatedLabel
				nameSpace='banners'
				i18nKey='entity.image_ar'
			/>
		),
		width: 82,
		render: (_, { type, image_ar }) =>
			type !== EBannerType.SWIMLANE ? <ImageCell imageSrc={image_ar} /> : DEFAULT_EMPTY_VALUE_PLACEHOLDER,
	},
	name: {
		key: 'name',
		title: (
			<TranslatedLabel
				nameSpace='banners'
				i18nKey='entity.name'
			/>
		),
		ellipsis: true,
		render: (_, { name, name_ar }) => (
			<DisplayNameAndNameAr
				name={name || DEFAULT_EMPTY_VALUE_PLACEHOLDER}
				nameAr={name_ar || DEFAULT_EMPTY_VALUE_PLACEHOLDER}
			/>
		),
	},

	title: {
		key: 'title',
		title: (
			<TranslatedLabel
				nameSpace='banners'
				i18nKey='entity.title'
			/>
		),
		width: 200,
		render: (_, { title, title_ar }) => (
			<DisplayNameAndNameAr
				name={title || DEFAULT_EMPTY_VALUE_PLACEHOLDER}
				nameAr={title_ar || DEFAULT_EMPTY_VALUE_PLACEHOLDER}
			/>
		),
	},

	position: {
		key: 'position',
		title: (
			<TranslatedLabel
				nameSpace='banners'
				i18nKey='entity.position'
			/>
		),
		width: 90,
		ellipsis: true,
		dataIndex: 'position',
	},
	vendors: {
		key: 'vendors',
		title: (
			<TranslatedLabel
				nameSpace='banners'
				i18nKey='entity.vendors'
			/>
		),
		width: 90,
		render: (_, { swimlane_vendors }) => {
			if (!swimlane_vendors || !swimlane_vendors.length) {
				return <T.Text>{DEFAULT_EMPTY_VALUE_PLACEHOLDER}</T.Text>;
			}

			return (
				<Tooltip
					title={swimlane_vendors.map((vendor: IVendorSwimlaneDetails) => {
						return <li key={vendor.vendor_id}>{`\u2022 (#${vendor.vendor_id}) ${vendor.name}`}</li>;
					})}
				>
					<Flex gap={6}>
						<ApartmentOutlined />
						<T.Text>{swimlane_vendors.length}</T.Text>
					</Flex>
				</Tooltip>
			);
		},
	},
	interaction_type: {
		key: 'interaction_type',
		title: (
			<TranslatedLabel
				nameSpace='banners'
				i18nKey='entity.interaction_type'
			/>
		),
		width: 160,
		render: (_, { interaction_type }) => (
			<TranslatedLabel
				nameSpace='banners'
				i18nKey={`entity.interaction_types.${interaction_type}`}
			/>
		),
		filterMultiple: false,
		filters: BANNER_INTERACTION_TYPE_FILTERS_CONFIG,
	},
	type: {
		key: 'type',
		title: (
			<TranslatedLabel
				nameSpace='banners'
				i18nKey={'entity.type'}
			/>
		),

		render: (_, { type }) => (
			<TranslatedLabel
				nameSpace='banners'
				i18nKey={`entity.banner_types.${type}`}
			/>
		),
		ellipsis: true,
		filterMultiple: false,
		filters: BANNER_TYPE_FILTERS_CONFIG,
	},
};

export const TABLE_CONFIGS_BY_E_BANNER_TYPE: Record<EBannerTypeTabs, ColumnsType<IBannersTableData>> = {
	[EBannerTypeTabs.HIGHLIGHTED_HOME]: [
		BANNERS_COLUMNS.id,
		BANNERS_COLUMNS.image,
		BANNERS_COLUMNS.image_ar,
		BANNERS_COLUMNS.name,
		BANNERS_COLUMNS.position,
		BANNERS_COLUMNS.interaction_type,
	],
	[EBannerTypeTabs.HIGHLIGHTED_ACCOUNT]: [
		BANNERS_COLUMNS.id,
		BANNERS_COLUMNS.image,
		BANNERS_COLUMNS.image_ar,
		BANNERS_COLUMNS.name,
		BANNERS_COLUMNS.position,
		BANNERS_COLUMNS.interaction_type,
	],
	[EBannerTypeTabs.PROMOTIONAL_SWIMLANE]: [
		BANNERS_COLUMNS.id,
		BANNERS_COLUMNS.image,
		BANNERS_COLUMNS.image_ar,
		BANNERS_COLUMNS.name,
		BANNERS_COLUMNS.title,
		BANNERS_COLUMNS.position,
		BANNERS_COLUMNS.vendors,
		BANNERS_COLUMNS.type,
		BANNERS_COLUMNS.interaction_type,
	],
};
